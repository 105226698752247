/**
 * Сервис для работы с категориями
 * Содержит методы для получения, создания, обновления и удаления категорий
 */
import api from '../../../services/api';

/**
 * Получение списка категорий
 * @returns {Promise<Array>} Массив категорий
 */
export const fetchCategories = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.get('/api/categories', { 
      headers: { 'Authorization': `Bearer ${token}` } 
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении категорий:', error);
    throw new Error(error.response?.data?.message || 'Не удалось получить категории');
  }
};

/**
 * Создание новой категории
 * @param {Object} categoryData - Данные новой категории
 * @returns {Promise<Object>} Созданная категория
 */
export const createCategory = async (categoryData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post('/api/categories', categoryData, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при создании категории:', error);
    throw new Error(error.response?.data?.message || 'Не удалось создать категорию');
  }
};

/**
 * Обновление существующей категории
 * @param {string} categoryId - ID категории
 * @param {Object} categoryData - Обновленные данные категории
 * @returns {Promise<Object>} Обновленная категория
 */
export const updateCategory = async (categoryId, categoryData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.put(`/api/categories/${categoryId}`, categoryData, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при обновлении категории:', error);
    throw new Error(error.response?.data?.message || 'Не удалось обновить категорию');
  }
};

/**
 * Удаление категории
 * @param {string} categoryId - ID категории
 * @returns {Promise<Object>} Результат удаления
 */
export const deleteCategory = async (categoryId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.delete(`/api/categories/${categoryId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при удалении категории:', error);
    throw new Error(error.response?.data?.message || 'Не удалось удалить категорию');
  }
};

/**
 * Заглушка для функции изменения порядка категории
 * @param {string} categoryId - ID категории
 * @param {string} direction - Направление ('up' или 'down')
 * @returns {Promise<Object>} Результат изменения порядка
 */
export const reorderCategory = async (categoryId, direction) => {
  // Просто возвращаем успешный результат, так как порядок не используется
  return { success: true };
};
