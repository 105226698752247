import React, { useState } from 'react';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Avatar,
  Divider,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  Menu as MenuIcon,
  ExitToApp as LogoutIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ArticleManager from './ArticleManager';
import ChannelModeration from './ChannelModeration';
import QuestionManager from './QuestionManager';
import Users from './Users';
import TelegramSettings from './TelegramSettings';
import Categories from './Categories';
import { useAdminAuth } from '../../hooks/useAdminAuth';
import { getCsrfToken } from '../../utils/csrfUtils';
import AdminSidebar from './AdminSidebar';
import AdminStats from './AdminStats';

// Стили для компонентов
const useStyles = {
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
  drawer: {
    width: 280,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 280,
  },
  content: {
    flexGrow: 1,
    padding: 0,
    height: '100vh',
    overflow: 'auto',
  },
  toolbar: (theme) => theme.mixins.toolbar,
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: '#0088cc',
    cursor: 'pointer',
  },
  userName: {
    marginLeft: 1,
    fontWeight: 500,
  },
  userMenu: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
};

const Dashboard = () => {
  useAdminAuth(); // Проверяем права администратора
  const theme = useTheme();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentSection, setCurrentSection] = useState('dashboard');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      // Получаем CSRF токен для запроса
      const csrfToken = getCsrfToken();
      
      // Отправляем запрос на выход
      await fetch('/api/auth/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        }
      });
    } catch (error) {
      console.error('Ошибка при выходе из системы:', error);
    }
    
    // Очищаем данные авторизации
    localStorage.removeItem('isAdminAuthenticated');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/');
  };

  // Функция для отображения контента в зависимости от выбранного раздела
  const renderContent = () => {
    switch (currentSection) {
      case 'dashboard':
        return <AdminStats />;
      case 'articles':
        return <ArticleManager />;
      case 'channels':
        return <ChannelModeration />;
      case 'categories':
        return <Categories />;
      case 'questions':
        return <QuestionManager />;
      case 'users':
        return <Users />;
      case 'telegram':
        return <TelegramSettings />;
      default:
        return <Box sx={{ p: 3 }}><Typography>Выберите раздел</Typography></Box>;
    }
  };

  // Получаем данные пользователя из localStorage
  const userStr = localStorage.getItem('user');
  const user = userStr ? JSON.parse(userStr) : { username: 'Администратор' };

  return (
    <Box sx={useStyles.root}>
      <AppBar position="fixed" sx={useStyles.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {currentSection === 'dashboard' ? 'Статистика' : 
             currentSection === 'articles' ? 'Управление статьями' :
             currentSection === 'channels' ? 'Модерация каналов' :
             currentSection === 'categories' ? 'Управление категориями' :
             currentSection === 'questions' ? 'Вопросы пользователей' :
             currentSection === 'users' ? 'Пользователи' :
             currentSection === 'telegram' ? 'Настройки Telegram' : 'Админ-панель'}
          </Typography>
          <Box sx={useStyles.userMenu}>
            <Avatar sx={useStyles.avatar} onClick={handleMenu}>
              {user.username.charAt(0).toUpperCase()}
            </Avatar>
            <Typography variant="body1" sx={useStyles.userName} onClick={handleMenu}>
              {user.username}
            </Typography>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <PersonIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Профиль</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Выйти</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={!isMobile || mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          ...useStyles.drawer,
          '& .MuiDrawer-paper': useStyles.drawerPaper,
        }}
      >
        <AdminSidebar 
          currentSection={currentSection} 
          setCurrentSection={setCurrentSection} 
        />
      </Drawer>
      
      <Box component="main" sx={useStyles.content}>
        <Toolbar />
        {renderContent()}
      </Box>
    </Box>
  );
};

export default Dashboard;
