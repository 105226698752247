import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControlLabel,
  Switch,
  Grid,
  Box,
  InputAdornment,
  Tooltip,
  Typography,
  FormHelperText,
  Button,
  Paper,
  alpha
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  Star as StarIcon,
  Home as HomeIcon,
  Link as LinkIcon,
  ColorLens as ColorLensIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import IconSelector from '../../../components/Admin/IconSelector';
import { getIconByName } from '../../../utils/iconRegistry';

/**
 * Компонент формы для создания/редактирования категории
 */
const CategoryForm = ({ initialValues = {}, onSubmit, loading }) => {
  // Состояние формы
  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    icon: '',
    color: '#000000',
    isActive: true,
    isPopular: false,
    showOnHomepage: false,
    ...initialValues
  });

  // Состояние ошибок валидации
  const [errors, setErrors] = useState({});

  // Обновление формы при изменении initialValues
  useEffect(() => {
    setFormData({
      name: '',
      slug: '',
      icon: '',
      color: '#000000',
      isActive: true,
      isPopular: false,
      showOnHomepage: false,
      ...initialValues
    });
    setErrors({});
  }, [initialValues]);

  // Обработчик изменения текстовых полей
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Очистка ошибки при изменении поля
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  // Обработчик изменения переключателей
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prev => ({ ...prev, [name]: checked }));
  };

  // Автоматическое создание slug из названия
  const handleNameChange = (e) => {
    const { value } = e.target;
    setFormData(prev => ({ 
      ...prev, 
      name: value,
      // Генерация slug только если поле slug пустое или не было изменено вручную
      slug: prev.slug === '' || prev.slug === slugify(prev.name) 
        ? slugify(value) 
        : prev.slug
    }));
    
    // Очистка ошибки при изменении поля
    if (errors.name) {
      setErrors(prev => ({ ...prev, name: '' }));
    }
  };

  // Преобразование строки в slug
  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')        // Замена пробелов на дефисы
      .replace(/[^\w\-]+/g, '')    // Удаление не-слов
      .replace(/\-\-+/g, '-')      // Замена нескольких дефисов на один
      .replace(/^-+/, '')          // Удаление дефисов в начале
      .replace(/-+$/, '');         // Удаление дефисов в конце
  };

  // Валидация формы
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Название категории обязательно';
    }
    
    if (!formData.slug.trim()) {
      newErrors.slug = 'Slug обязателен';
    } else if (!/^[a-z0-9-]+$/.test(formData.slug)) {
      newErrors.slug = 'Slug может содержать только строчные буквы, цифры и дефисы';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Обработчик отправки формы
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  // Обработчик изменения цвета
  const handleColorChange = (e) => {
    setFormData(prev => ({ ...prev, color: e.target.value }));
  };

  // Состояние для диалога выбора иконки
  const [iconSelectorOpen, setIconSelectorOpen] = useState(false);

  // Обработчик выбора иконки
  const handleIconSelect = (iconName) => {
    setFormData(prev => ({ ...prev, icon: iconName }));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        {/* Название категории */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Название категории
          </Typography>
          <TextField
            name="name"
            value={formData.name}
            onChange={handleNameChange}
            error={!!errors.name}
            helperText={errors.name}
            fullWidth
            required
            disabled={loading}
            autoFocus
            placeholder="Например: Образование"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <StarIcon fontSize="small" color="action" />
                </InputAdornment>
              ),
            }}
            size="medium"
          />
        </Grid>
        
        {/* Slug */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Slug <Typography component="span" variant="caption" color="text.secondary">*</Typography>
          </Typography>
          <TextField
            name="slug"
            value={formData.slug}
            onChange={handleChange}
            error={!!errors.slug}
            helperText={errors.slug}
            fullWidth
            required
            disabled={loading}
            placeholder="Например: education"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon fontSize="small" color="action" />
                </InputAdornment>
              ),
            }}
            size="medium"
          />
          <FormHelperText>Уникальный идентификатор для URL</FormHelperText>
        </Grid>
        
        <Grid item xs={12} container spacing={3}>
          {/* Иконка */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Иконка
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  width: '100%',
                  mb: 1,
                  bgcolor: formData.color ? alpha(formData.color, 0.05) : 'transparent'
                }}
              >
                <Box 
                  sx={{ 
                    width: 40, 
                    height: 40, 
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: formData.color ? alpha(formData.color, 0.1) : 'action.hover',
                    color: formData.color || 'text.primary',
                    mr: 2
                  }}
                >
                  {formData.icon ? 
                    React.createElement(getIconByName(formData.icon), { sx: { fontSize: 24 } }) : 
                    <StarIcon sx={{ fontSize: 24 }} />
                  }
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {formData.icon ? formData.icon.replace('Icon', '') : 'Не выбрана'}
                  </Typography>
                </Box>
                <Button 
                  variant="outlined" 
                  size="small" 
                  onClick={() => setIconSelectorOpen(true)}
                  startIcon={<EditIcon />}
                  sx={{ ml: 1 }}
                >
                  Выбрать
                </Button>
              </Paper>
            </Box>
            <FormHelperText>
              Иконка для визуального отображения категории
            </FormHelperText>
          </Grid>
          
          {/* Цвет */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Цвет
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  width: '100%',
                  mb: 1
                }}
              >
                <Box 
                  sx={{ 
                    width: 40, 
                    height: 40, 
                    borderRadius: 1,
                    bgcolor: formData.color || '#e0e0e0',
                    mr: 2,
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {formData.color || 'Не выбран'}
                  </Typography>
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    startIcon={<ColorLensIcon />}
                    sx={{ ml: 1 }}
                  >
                    Выбрать
                  </Button>
                  <input
                    type="color"
                    name="color"
                    value={formData.color}
                    onChange={handleColorChange}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      opacity: 0,
                      cursor: 'pointer'
                    }}
                  />
                </Box>
              </Paper>
            </Box>
            <FormHelperText>
              Цвет для визуального оформления категории
            </FormHelperText>
          </Grid>
        </Grid>
        
        {/* Переключатели */}
        <Grid item xs={12}>
          <Box sx={{ mt: 1 }}>
            <Typography variant="subtitle2" gutterBottom>
              Настройки отображения
            </Typography>
            
            <FormControlLabel
              control={
                <Switch
                  checked={formData.isActive}
                  onChange={handleSwitchChange}
                  name="isActive"
                  color="primary"
                  disabled={loading}
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <VisibilityIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography>Активная категория</Typography>
                </Box>
              }
              sx={{ mb: 1, display: 'flex' }}
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={formData.isPopular}
                  onChange={handleSwitchChange}
                  name="isPopular"
                  color="primary"
                  disabled={loading}
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StarIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography>Популярная категория</Typography>
                </Box>
              }
              sx={{ mb: 1, display: 'flex' }}
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={formData.showOnHomepage}
                  onChange={handleSwitchChange}
                  name="showOnHomepage"
                  color="primary"
                  disabled={loading}
                />
              }
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <HomeIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography>Показывать на главной</Typography>
                </Box>
              }
              sx={{ display: 'flex' }}
            />
          </Box>
        </Grid>
        
        {/* Кнопки действий */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{ minWidth: 120 }}
            >
              Сохранить
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Диалог выбора иконки */}
      <IconSelector
        open={iconSelectorOpen}
        onClose={() => setIconSelectorOpen(false)}
        onSelect={handleIconSelect}
        selectedIcon={formData.icon}
      />
    </Box>
  );
};

export default CategoryForm;
