import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Chip,
  IconButton,
  DialogContentText,
  Snackbar,
  Alert,
  Tooltip,
  Divider,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format, formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useAdminAuth } from '../../hooks/useAdminAuth';
import api from '../../services/api';
import { secureDelete } from '../../utils/secureDelete';

const QuestionManager = () => {
  useAdminAuth(); // Проверяем права администратора

  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Получение списка вопросов
  const fetchQuestions = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await api.get('/api/questions');
      setQuestions(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке вопросов:', error);
      setError('Не удалось загрузить вопросы');
      
      // Если ошибка авторизации, не показываем ошибку, так как useAdminAuth перенаправит
      if (error.response && error.response.status !== 401) {
        setError('Не удалось загрузить вопросы: ' + (error.response?.data?.message || error.message));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  // Фильтруем вопросы по статусу
  const filteredQuestions = questions.filter(question => {
    if (currentTab === 0) return question.status === 'pending';
    if (currentTab === 1) return question.status === 'answered';
    return true;
  });

  const handleViewQuestion = (question) => {
    setSelectedQuestion(question);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedQuestion(null);
  };

  const handleMarkAsAnswered = async (id) => {
    try {
      await api.patch(`/api/questions/${id}/status`, { status: 'answered' });
      setSnackbarOpen(true);
      setSnackbarMessage('Статус вопроса успешно изменен');
      fetchQuestions();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDeleteQuestion = async () => {
    try {
      await secureDelete(`/api/questions/${deleteQuestionId}`);
      setOpenDeleteDialog(false);
      setDeleteQuestionId(null);
      setSnackbarOpen(true);
      setSnackbarMessage('Вопрос успешно удален');
      fetchQuestions();
    } catch (error) {
      // Если требуется подтверждение, показываем модальное окно
      if (error.message === 'CONFIRMATION_REQUIRED') {
        // Модальное окно уже открыто, отправляем запрос с подтверждением
        try {
          await api.delete(`/api/questions/${deleteQuestionId}`, {
            headers: {
              'X-Admin-Confirmation': 'confirm-delete'
            }
          });
          setOpenDeleteDialog(false);
          setDeleteQuestionId(null);
          setSnackbarOpen(true);
          setSnackbarMessage('Вопрос успешно удален');
          fetchQuestions();
        } catch (confirmError) {
          console.error('Error deleting question after confirmation:', confirmError);
        }
      } else {
        console.error('Error deleting question:', error);
      }
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteQuestionId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteQuestionId(null);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getStatusChip = (status) => {
    switch (status) {
      case 'pending':
        return <Chip label="Новый" color="primary" size="small" />;
      case 'answered':
        return <Chip label="Отвечен" color="success" size="small" />;
      default:
        return <Chip label={status} size="small" />;
    }
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'dd.MM.yyyy HH:mm');
  };

  const formatTimeAgo = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true, locale: ru });
  };

  const handleCopyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
      setSnackbarMessage(`${type} скопирован в буфер обмена`);
    });
  };

  // Функция для проверки на ссылки
  const containsLinks = (str) => {
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([^\s]+\.[a-zA-Z]{2,})/gi;
    return urlRegex.test(str);
  };

  if (loading) {
    return (
      <Box sx={{ width: '100%', p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ width: '100%', p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: '#666666', fontWeight: 500, fontSize: '1.1rem' }}>
          Ошибка загрузки вопросов
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Новые вопросы" />
          <Tab label="Архив" />
        </Tabs>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>Пользователь</TableCell>
              <TableCell>Вопрос</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell>Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredQuestions.map((question) => (
              <TableRow key={question._id}>
                <TableCell>
                  {formatDate(question.createdAt)}
                </TableCell>
                <TableCell>{question.user?.username || 'Неизвестно'}</TableCell>
                <TableCell sx={{ maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {question.question}
                </TableCell>
                <TableCell>{getStatusChip(question.status)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleViewQuestion(question)} title="Просмотреть">
                    <VisibilityIcon />
                  </IconButton>
                  {question.status === 'pending' && (
                    <IconButton onClick={() => handleMarkAsAnswered(question._id)} title="Пометить как отвеченный">
                      <CheckCircleIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={() => handleOpenDeleteDialog(question._id)} title="Удалить">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Диалог просмотра вопроса */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
            backgroundColor: '#fafafa'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid rgba(0,0,0,0.08)',
          pb: 2,
          pt: 3,
          px: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          backgroundColor: '#fff'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h5" component="div" sx={{ 
              color: '#1a1a1a', 
              fontWeight: 600,
              fontSize: '1.5rem'
            }}>
              Информация о вопросе
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {selectedQuestion && getStatusChip(selectedQuestion.status)}
              <Tooltip title="Закрыть">
                <IconButton 
                  onClick={handleCloseDialog} 
                  size="small"
                  sx={{
                    color: '#666666',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.04)'
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          {selectedQuestion && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                <Tooltip title={formatDate(selectedQuestion.createdAt)}>
                  <Typography variant="subtitle1" sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                    color: '#666666',
                    fontWeight: 500,
                    fontSize: '0.95rem'
                  }}>
                    <AccessTimeIcon fontSize="small" />
                    {formatTimeAgo(selectedQuestion.createdAt)}
                  </Typography>
                </Tooltip>
                {selectedQuestion.user && (
                  <Typography variant="subtitle1" sx={{ 
                    color: '#666666', 
                    fontWeight: 500,
                    fontSize: '0.95rem'
                  }}>
                    От пользователя: {selectedQuestion.user.username || 'Неизвестно'}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </DialogTitle>
        <DialogContent 
          sx={{ 
            py: 4,
            px: 3,
            backgroundColor: '#fafafa',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              borderRadius: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.15)',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
              },
            },
          }}
        >
          {selectedQuestion && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {/* Контактная информация */}
              <Paper elevation={0} sx={{ 
                p: 3, 
                backgroundColor: '#fff',
                borderRadius: 2,
                border: '1px solid rgba(0,0,0,0.06)'
              }}>
                <Typography variant="h6" sx={{ 
                  mb: 2.5, 
                  fontWeight: 600, 
                  color: '#1a1a1a',
                  fontSize: '1.1rem'
                }}>
                  Контактная информация
                </Typography>
                <Box sx={{ display: 'flex', gap: 4 }}>
                  {selectedQuestion.email && (
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" sx={{ 
                        color: '#666666',
                        fontWeight: 500,
                        mb: 1.5,
                        fontSize: '0.95rem'
                      }}>
                        Email:
                      </Typography>
                      <Tooltip title="Нажмите, чтобы скопировать">
                        <Box sx={{ 
                          p: 2,
                          borderRadius: 1.5,
                          backgroundColor: '#f8f8f8',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                          transition: 'all 0.2s ease',
                          border: '1px solid rgba(0,0,0,0.04)',
                          '&:hover': {
                            backgroundColor: '#f3f3f3',
                            transform: 'translateY(-1px)',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
                          }
                        }}
                        onClick={() => handleCopyToClipboard(selectedQuestion.email, 'Email')}
                        >
                          <Typography variant="body1" sx={{ 
                            flex: 1,
                            color: '#333333',
                            fontWeight: 500,
                            fontSize: '1rem'
                          }}>
                            {selectedQuestion.email}
                          </Typography>
                          <IconButton size="small" sx={{ 
                            ml: 1, 
                            color: '#666666',
                            '&:hover': {
                              color: '#333333'
                            }
                          }}>
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  )}
                  {selectedQuestion.telegram && (
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" sx={{ 
                        color: '#666666',
                        fontWeight: 500,
                        mb: 1.5,
                        fontSize: '0.95rem'
                      }}>
                        Telegram:
                      </Typography>
                      <Tooltip title="Нажмите, чтобы скопировать">
                        <Box sx={{ 
                          p: 2,
                          borderRadius: 1.5,
                          backgroundColor: '#f8f8f8',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                          transition: 'all 0.2s ease',
                          border: '1px solid rgba(0,0,0,0.04)',
                          '&:hover': {
                            backgroundColor: '#f3f3f3',
                            transform: 'translateY(-1px)',
                            boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
                          }
                        }}
                        onClick={() => handleCopyToClipboard(selectedQuestion.telegram, 'Telegram')}
                        >
                          <Typography variant="body1" sx={{ 
                            flex: 1,
                            color: '#333333',
                            fontWeight: 500,
                            fontSize: '1rem'
                          }}>
                            {selectedQuestion.telegram}
                          </Typography>
                          <IconButton size="small" sx={{ 
                            ml: 1, 
                            color: '#666666',
                            '&:hover': {
                              color: '#333333'
                            }
                          }}>
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Paper>

              {/* Текст вопроса */}
              <Paper elevation={0} sx={{ 
                p: 3, 
                backgroundColor: '#fff',
                borderRadius: 2,
                border: '1px solid rgba(0,0,0,0.06)'
              }}>
                <Typography variant="h6" sx={{ 
                  mb: 2.5, 
                  fontWeight: 600, 
                  color: '#1a1a1a',
                  fontSize: '1.1rem'
                }}>
                  Текст вопроса
                </Typography>
                <Typography variant="body1" sx={{ 
                  whiteSpace: 'pre-wrap',
                  backgroundColor: '#f8f8f8',
                  p: 3,
                  borderRadius: 1.5,
                  border: '1px solid rgba(0,0,0,0.04)',
                  maxHeight: '300px',
                  overflowY: 'auto',
                  color: '#333333',
                  fontWeight: 400,
                  lineHeight: 1.7,
                  fontSize: '1rem',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0, 0, 0, 0.15)',
                    borderRadius: '4px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    },
                  },
                }}>
                  {selectedQuestion.question}
                </Typography>
              </Paper>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid rgba(0,0,0,0.08)',
          px: 3,
          py: 2.5,
          backgroundColor: '#fff',
          gap: 2
        }}>
          {selectedQuestion && selectedQuestion.status === 'pending' && (
            <Button
              onClick={() => {
                handleMarkAsAnswered(selectedQuestion._id);
                handleCloseDialog();
              }}
              startIcon={<CheckCircleIcon />}
              variant="contained"
              color="primary"
              sx={{ 
                mr: 'auto',
                fontWeight: 600,
                px: 3,
                py: 1,
                fontSize: '0.95rem',
                textTransform: 'none',
                borderRadius: 1.5,
                boxShadow: '0 2px 8px rgba(25, 118, 210, 0.15)',
                '&:hover': {
                  boxShadow: '0 4px 12px rgba(25, 118, 210, 0.2)'
                }
              }}
            >
              Отметить как отвеченный
            </Button>
          )}
          <Tooltip title="Удалить вопрос">
            <Button
              onClick={() => {
                handleOpenDeleteDialog(selectedQuestion._id);
                handleCloseDialog();
              }}
              startIcon={<DeleteIcon />}
              color="error"
              sx={{ 
                fontWeight: 600,
                px: 3,
                py: 1,
                fontSize: '0.95rem',
                textTransform: 'none',
                borderRadius: 1.5
              }}
            >
              Удалить
            </Button>
          </Tooltip>
          <Button 
            onClick={handleCloseDialog}
            variant="outlined"
            startIcon={<CloseIcon />}
            sx={{ 
              fontWeight: 600,
              px: 3,
              py: 1,
              fontSize: '0.95rem',
              textTransform: 'none',
              borderRadius: 1.5
            }}
          >
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>

      {/* Диалог подтверждения удаления */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Подтверждение удаления</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены, что хотите удалить этот вопрос? Это действие нельзя отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Отмена</Button>
          <Button onClick={handleDeleteQuestion} color="error">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar для уведомлений */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity="success" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QuestionManager;
