import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  IconButton,
  Grid,
  Avatar,
  styled,
  Tab,
  Tabs,
  CircularProgress,
  Paper,
  Chip,
  TextField,
  InputAdornment,
  Pagination,
  Alert,
} from '@mui/material';
import {
  CheckCircle as ApproveIcon,
  Cancel,
  Delete as DeleteIcon,
  Telegram as TelegramIcon,
  WorkspacePremium as WorkspacePremiumIcon,
  Refresh as RefreshIcon,
  OpenInNew as ViewIcon,
  Person as PersonIcon,
  Star as StarIcon,
  AccessTime,
  CheckCircle,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useAdminAuth } from '../../hooks/useAdminAuth';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { secureDelete } from '../../utils/secureDelete';
import api from '../../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1.5),
  backgroundColor: theme.palette.background.default,
  transition: 'all 0.2s ease-in-out',
  borderLeft: '4px solid transparent',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  '&[data-status="pending"]': {
    borderLeftColor: theme.palette.warning.main,
  },
  '&[data-status="approved"]': {
    borderLeftColor: theme.palette.success.main,
  },
  '&[data-status="rejected"]': {
    borderLeftColor: theme.palette.error.main,
  }
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  height: 32,
  borderRadius: 16,
  fontSize: '0.9rem',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '& .MuiChip-label': {
    padding: '0 16px',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

const CategoryChipsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

const PremiumChip = styled(Chip)(({ theme }) => ({
  height: 32,
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
  fontWeight: 600,
  '& .MuiChip-label': {
    padding: '0 16px',
  },
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  '&:hover': {
    backgroundColor: theme.palette.warning.dark,
  }
}));

const DetailSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  height: '100%',
  boxShadow: theme.shadows[1],
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
  transition: 'box-shadow 0.3s ease-in-out',
}));

const StatusChip = styled(Chip)(({ theme, status }) => ({
  borderRadius: theme.shape.borderRadius,
  fontWeight: 500,
  backgroundColor: 
    status === 'pending' ? theme.palette.warning.light :
    status === 'approved' ? theme.palette.success.light :
    theme.palette.error.light,
  color: 
    status === 'pending' ? theme.palette.warning.dark :
    status === 'approved' ? theme.palette.success.dark :
    theme.palette.error.dark,
  '& .MuiChip-label': {
    padding: '4px 8px',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
  padding: '6px 16px',
  fontWeight: 500,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
}));

const DialogHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
}));

const StyledDialogContent = styled(Box)(({ theme }) => ({
  padding: 0,
  '& .MuiGrid-container': {
    margin: 0,
    width: '100%',
  },
}));

const StyledDialogActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  padding: theme.spacing(2, 3),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
}));

const PremiumBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[2],
    borderColor: theme.palette.primary.light,
  }
}));

const PremiumIcon = styled(WorkspacePremiumIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontSize: '2rem',
}));

const RecommendedIcon = styled(StarIcon)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '2rem',
}));

const ActionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

const CompactButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.813rem',
  textTransform: 'none',
  marginRight: theme.spacing(1),
  '&:last-child': {
    marginRight: 0
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 48,
  height: 48,
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
  fontSize: '1.2rem',
  fontWeight: 500,
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[2],
}));

const StatusIconWrapper = styled(Box)(({ theme, status }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: 
    status === 'pending' ? theme.palette.warning.light :
    status === 'approved' ? theme.palette.success.light :
    theme.palette.error.light,
  color: 
    status === 'pending' ? theme.palette.warning.dark :
    status === 'approved' ? theme.palette.success.dark :
    theme.palette.error.dark,
  marginRight: theme.spacing(2),
}));

const ActionButtons = ({ 
  channel, 
  onDelete, 
  onTogglePremium, 
  onToggleRecommended, 
  onApprove, 
  onReject, 
  loading,
  onChannelUpdate 
}) => {
  const handleOpenChannel = () => {
    window.open(`/channel/${channel.slug}`, '_blank');
  };

  const handleTogglePremium = async () => {
    await onTogglePremium();
    onChannelUpdate();
  };

  const handleToggleRecommended = async () => {
    await onToggleRecommended();
    onChannelUpdate();
  };

  return (
    <Box sx={{ 
      p: 2,
      backgroundColor: 'background.paper',
      borderRadius: 1,
      border: 1,
      borderColor: 'divider'
    }}>
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <CompactButton
          variant="outlined"
          color="primary"
          onClick={handleOpenChannel}
          startIcon={<ViewIcon sx={{ fontSize: '1rem' }} />}
        >
          Открыть канал
        </CompactButton>
        <CompactButton
          variant={channel.isPremium ? "contained" : "outlined"}
          color={channel.isPremium ? "warning" : "primary"}
          onClick={handleTogglePremium}
          disabled={loading}
          startIcon={<WorkspacePremiumIcon sx={{ fontSize: '1rem' }} />}
        >
          {channel.isPremium ? 'Снять премиум' : 'Сделать премиум'}
        </CompactButton>
        <CompactButton
          variant={channel.isRecommended ? "contained" : "outlined"}
          color={channel.isRecommended ? "info" : "primary"}
          onClick={handleToggleRecommended}
          disabled={loading}
          startIcon={<StarIcon sx={{ fontSize: '1rem' }} />}
        >
          {channel.isRecommended ? 'Убрать из рекомендаций' : 'Рекомендовать'}
        </CompactButton>
        {channel.moderationStatus === 'pending' && (
          <>
            <CompactButton
              variant="outlined"
              color="success"
              onClick={onApprove}
              disabled={loading}
              startIcon={<ApproveIcon sx={{ fontSize: '1rem' }} />}
            >
              Одобрить
            </CompactButton>
            <CompactButton
              variant="outlined"
              color="error"
              onClick={onReject}
              disabled={loading}
              startIcon={<Cancel />}
            >
              Отклонить
            </CompactButton>
          </>
        )}
        <CompactButton
          variant="outlined"
          color="error"
          onClick={onDelete}
          disabled={loading}
          startIcon={<DeleteIcon sx={{ fontSize: '1rem' }} />}
        >
          Удалить
        </CompactButton>
      </Box>
    </Box>
  );
};

const formatSubscribers = (count) => {
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}M`;
  }
  if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K`;
  }
  return count.toString();
};

const PremiumStatus = ({ channel, onTogglePremium, loading }) => (
  <PremiumBox>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      flexWrap: 'nowrap',
      gap: 2
    }}>
      <StatusIconWrapper status={channel.moderationStatus}>
        {channel.moderationStatus === 'pending' && <AccessTime />}
        {channel.moderationStatus === 'approved' && <CheckCircle />}
        {channel.moderationStatus === 'rejected' && <Cancel />}
      </StatusIconWrapper>
      <Box sx={{ flex: '1 1 auto', minWidth: 0 }}>
        <Typography variant="h6" component="div" sx={{ 
          fontWeight: 600,
          fontSize: '1rem',
          mb: 0.5
        }}>
          Премиум статус
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {channel.isPremium ? 'Премиум канал' : 'Обычный канал'}
        </Typography>
      </Box>
      <Box sx={{ flex: '0 0 auto' }}>
        <ActionButton
          variant={channel.isPremium ? "outlined" : "contained"}
          color={channel.isPremium ? "error" : "warning"}
          onClick={onTogglePremium}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={16} /> : null}
        >
          {channel.isPremium ? 'Отключить премиум' : 'Сделать премиум'}
        </ActionButton>
      </Box>
    </Box>
  </PremiumBox>
);

const RecommendedStatus = ({ channel, onToggleRecommended, loading }) => (
  <PremiumBox>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      flexWrap: 'nowrap',
      gap: 2
    }}>
      <StatusIconWrapper status={channel.moderationStatus}>
        {channel.moderationStatus === 'pending' && <AccessTime />}
        {channel.moderationStatus === 'approved' && <CheckCircle />}
        {channel.moderationStatus === 'rejected' && <Cancel />}
      </StatusIconWrapper>
      <Box sx={{ flex: '1 1 auto', minWidth: 0 }}>
        <Typography variant="h6" component="div" sx={{ 
          fontWeight: 600,
          fontSize: '1rem',
          mb: 0.5
        }}>
          Рекомендуемый канал
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {channel.isRecommended ? 'В рекомендациях' : 'Не в рекомендациях'}
        </Typography>
      </Box>
      <Box sx={{ flex: '0 0 auto' }}>
        <ActionButton
          variant={channel.isRecommended ? "outlined" : "contained"}
          color={channel.isRecommended ? "error" : "info"}
          onClick={onToggleRecommended}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={16} /> : null}
        >
          {channel.isRecommended ? 'Убрать из рекомендаций' : 'В рекомендации'}
        </ActionButton>
      </Box>
    </Box>
  </PremiumBox>
);

const ChannelModeration = () => {
  useAdminAuth(); // Проверяем права администратора
  const [selectedTab, setSelectedTab] = useState(0);
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [updatingStats, setUpdatingStats] = useState(false);
  const [updatingRecommended, setUpdatingRecommended] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    total: 0,
    pages: 1,
    perPage: 24
  });
  const [channelToDelete, setChannelToDelete] = useState(null);

  const statusMap = {
    0: 'pending',
    1: 'approved',
    2: 'rejected'
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    fetchChannels(1, '');
  }, [selectedTab]);

  // Debounced поиск
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      fetchChannels(1, searchValue);
    }, 500),
    [selectedTab]
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handlePageChange = (event, newPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchChannels(newPage, searchTerm);
  };

  const fetchChannels = async (page, search) => {
    try {
      setLoading(true);
      
      // Формируем URL с параметрами фильтрации и пагинации
      let url = `/api/channels/admin/moderation/${statusMap[selectedTab]}?page=${page}&limit=${pagination.perPage}`;
      
      if (search) {
        url += `&search=${encodeURIComponent(search)}`;
      }
      
      const response = await api.get(url);
      
      if (response && response.data) {
        setChannels(response.data.channels || []);
        setPagination(prev => ({
          ...prev,
          ...response.data.pagination
        }));
        setError(null);
      } else {
        throw new Error('Некорректный ответ от сервера');
      }
    } catch (err) {
      console.error('Error fetching channels:', err);
      setError('Ошибка при загрузке каналов');
      setChannels([]);
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePremium = async (channelId) => {
    try {
      await api.patch(`/api/channels/${channelId}/premium`);
      await fetchChannels();
    } catch (err) {
      console.error('Error toggling premium status:', err);
      setError('Ошибка при изменении премиум статуса');
    }
  };

  const handleToggleRecommended = async (channelId) => {
    try {
      setUpdatingRecommended(true);
      await api.patch(`/api/channels/${channelId}/recommended`);
      await fetchChannels();
      // Обновляем состояние выбранного канала
      if (selectedChannel && selectedChannel._id === channelId) {
        setSelectedChannel(prev => ({
          ...prev,
          isRecommended: !prev.isRecommended
        }));
      }
    } catch (err) {
      console.error('Error toggling recommended status:', err);
      setError('Ошибка при изменении статуса рекомендации');
    } finally {
      setUpdatingRecommended(false);
    }
  };

  const handleDelete = async (channelId) => {
    try {
      // Пытаемся удалить канал напрямую
      await api.delete(`/api/channels/${channelId}`);
      setConfirmDelete(false);
      setOpenDialog(false); // Закрываем диалоговое окно просмотра канала
      await fetchChannels();
    } catch (err) {
      // Если требуется подтверждение, показываем модальное окно
      if (err.message === 'CONFIRMATION_REQUIRED' || 
          (err.response && err.response.status === 403 && 
           err.response.data && err.response.data.message?.includes('требуется дополнительное подтверждение'))) {
        setChannelToDelete(channelId);
        setConfirmDelete(true);
      } else {
        console.error('Error deleting channel:', err);
        setError('Ошибка при удалении канала');
      }
    }
  };

  const handleApprove = async (channelId) => {
    try {
      await api.patch(`/api/channels/${channelId}/moderation-status`, {
        status: 'approved'
      });
      await fetchChannels(pagination.currentPage, searchTerm);
      handleCloseDialog();
    } catch (err) {
      console.error('Error approving channel:', err);
      setError('Ошибка при одобрении канала');
    }
  };

  const handleReject = async (channelId) => {
    try {
      await api.patch(`/api/channels/${channelId}/moderation-status`, {
        status: 'rejected'
      });
      await fetchChannels(pagination.currentPage, searchTerm);
      handleCloseDialog();
    } catch (err) {
      console.error('Error rejecting channel:', err);
      setError('Ошибка при отклонении канала');
    }
  };

  const handleViewChannel = (channel) => {
    setSelectedChannel(channel);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setConfirmDelete(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUpdateStats = async (channelId) => {
    try {
      setUpdatingStats(true);
      const response = await api.post(`/api/channels/${channelId}/update-stats`);
      // Обновляем данные канала в состоянии
      setSelectedChannel(prev => ({
        ...prev,
        subscribers: response.data.subscribers
      }));
      // Обновляем данные в списке каналов
      setChannels(prevChannels => 
        prevChannels.map(ch => 
          ch._id === channelId 
            ? { ...ch, subscribers: response.data.subscribers }
            : ch
        )
      );
    } catch (error) {
      console.error('Error updating channel stats:', error);
      setError('Ошибка при обновлении статистики канала');
    } finally {
      setUpdatingStats(false);
    }
  };

  const handleChannelUpdate = async () => {
    try {
      const response = await api.get(`/api/channels/${selectedChannel._id}`);
      setSelectedChannel(response.data);
    } catch (error) {
      console.error('Error updating channel info:', error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      // Используем api.delete с заголовком подтверждения
      await api.delete(`/api/channels/${channelToDelete}`, {
        headers: {
          'X-Confirmation': 'confirm-delete'
        }
      });
      setConfirmDelete(false);
      setOpenDialog(false);
      await fetchChannels();
    } catch (err) {
      console.error('Error deleting channel after confirmation:', err);
      setError('Ошибка при удалении канала');
    }
  };

  return (
    <Box>
      <Box sx={{ bgcolor: 'background.default', borderRadius: 1, p: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider',
          pb: 2,
          mb: 3,
          flexWrap: 'wrap',
          gap: 2
        }}>
          <Typography variant="h5" component="h1" sx={{ flexGrow: 1, fontWeight: 600 }}>
            Channel Moderation
          </Typography>
          <TextField
            placeholder="Search channels..."
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            sx={{ minWidth: 300 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Tabs 
          value={selectedTab} 
          onChange={handleTabChange}
          sx={{ 
            mb: 3,
            '& .MuiTabs-indicator': {
              backgroundColor: 'primary.main',
            }
          }}
        >
          <Tab 
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTime sx={{ mr: 1 }} />
                Pending
              </Box>
            } 
          />
          <Tab 
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircle sx={{ mr: 1 }} />
                Approved
              </Box>
            } 
          />
          <Tab 
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Cancel sx={{ mr: 1 }} />
                Rejected
              </Box>
            } 
          />
        </Tabs>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        ) : channels.length === 0 ? (
          <Typography variant="body1" color="text.secondary" align="center" sx={{ py: 4 }}>
            Каналов не найдено
          </Typography>
        ) : (
          <Box sx={{ 
            bgcolor: 'background.paper',
            borderRadius: 1,
            boxShadow: 1,
            overflow: 'hidden'
          }}>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={2}>
                {channels.map((channel) => (
                  <Grid item xs={12} key={channel._id}>
                    <StyledPaper
                      data-status={channel.moderationStatus}
                      onClick={() => handleViewChannel(channel)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Avatar
                          src={channel.avatar}
                          alt={channel.name}
                          sx={{
                            width: 60,
                            height: 60,
                            marginRight: 2
                          }}
                        />
                        
                        <Box sx={{ 
                          minWidth: 0, 
                          flex: '0 0 200px',
                          overflow: 'hidden'
                        }}>
                          <Typography 
                            variant="subtitle1" 
                            component="div" 
                            sx={{ 
                              display: 'flex', 
                              alignItems: 'center',
                              gap: 1,
                              fontWeight: 500,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            <span style={{ 
                              overflow: 'hidden', 
                              textOverflow: 'ellipsis', 
                              whiteSpace: 'nowrap' 
                            }}>
                              {channel.name}
                            </span>
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            @{channel.username} • {formatSubscribers(channel.subscribers)}
                          </Typography>
                        </Box>

                        <CategoryChipsContainer sx={{ flex: 1, mx: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                          {channel.isPremium && (
                            <PremiumChip
                              label="PREMIUM"
                              size="small"
                              sx={{ 
                                minWidth: 100,
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                  transform: 'translateY(-2px)'
                                }
                              }}
                            />
                          )}
                          {channel.categories.map((category) => (
                            <CategoryChip
                              key={category}
                              label={category}
                              sx={{ 
                                minWidth: 100,
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                  transform: 'translateY(-2px)'
                                }
                              }}
                            />
                          ))}
                        </CategoryChipsContainer>

                        <Box sx={{ display: 'flex', gap: 1, ml: 'auto', flexShrink: 0 }}>
                          {(channel.moderationStatus === 'approved' || channel.moderationStatus === 'rejected') && (
                            <IconButton
                              size="small"
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedChannel(channel);
                                setChannelToDelete(channel._id);
                                setConfirmDelete(true);
                              }}
                              sx={{ ml: 1 }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                          {channel.moderationStatus === 'pending' && (
                            <>
                              <ActionButton
                                startIcon={<ApproveIcon />}
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleApprove(channel._id);
                                }}
                              >
                                Одобрить
                              </ActionButton>
                              <ActionButton
                                startIcon={<Cancel />}
                                variant="contained"
                                color="error"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleReject(channel._id);
                                }}
                              >
                                Отклонить
                              </ActionButton>
                            </>
                          )}
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(`/channel/${channel.slug}`, '_blank');
                            }}
                            color="primary"
                            size="small"
                            sx={{ 
                              transition: 'all 0.2s ease-in-out',
                              '&:hover': {
                                transform: 'rotate(90deg)'
                              }
                            }}
                          >
                            <ViewIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </StyledPaper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        )}

        {channels.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Pagination 
              count={pagination.pages}
              page={pagination.currentPage}
              onChange={handlePageChange}
              color="primary"
              showFirstButton 
              showLastButton
            />
          </Box>
        )}
      </Box>

      <Dialog
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden',
            maxWidth: 900
          }
        }}
      >
        {selectedChannel && (
          <>
            <DialogHeader>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar
                  src={selectedChannel.avatar}
                  alt={selectedChannel.name}
                  sx={{
                    width: 64,
                    height: 64,
                  }}
                />
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {selectedChannel.name}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                    <Typography variant="body2" color="text.secondary">
                      @{selectedChannel.username}
                    </Typography>
                    <StatusChip
                      label={
                        selectedChannel.moderationStatus === 'pending' ? 'На модерации' :
                        selectedChannel.moderationStatus === 'approved' ? 'Одобрен' :
                        'Отклонен'
                      }
                      status={selectedChannel.moderationStatus}
                      size="small"
                    />
                  </Box>
                </Box>
              </Box>
              <IconButton 
                onClick={() => setOpenDialog(false)}
                sx={{ color: 'text.secondary' }}
              >
                <Cancel />
              </IconButton>
            </DialogHeader>

            <DialogContent sx={{ p: 0 }}>
              <Grid container>
                {/* Левая колонка */}
                <Grid item xs={12} md={4} sx={{ 
                  borderRight: { md: 1 }, 
                  borderColor: 'divider',
                  bgcolor: 'background.default'
                }}>
                  <Box sx={{ p: 3 }}>
                    {/* Статистика */}
                    <Box sx={{ mb: 4 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        mb: 2
                      }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                          Статистика
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleUpdateStats(selectedChannel._id)}
                          disabled={updatingStats}
                        >
                          {updatingStats ? <CircularProgress size={20} /> : <RefreshIcon />}
                        </IconButton>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box>
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            Подписчиков
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            {formatSubscribers(selectedChannel.subscribers)}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            Добавлен
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <PersonIcon sx={{ fontSize: '1rem', color: 'text.secondary' }} />
                            <Typography variant="body1">
                              {selectedChannel.owner?.username || 'Неизвестно'}
                            </Typography>
                          </Box>
                          <Typography variant="caption" color="text.secondary">
                            {new Date(selectedChannel.createdAt).toLocaleDateString()}
                          </Typography>
                        </Box>
                        {selectedChannel.moderatedBy && (
                          <Box>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                              {selectedChannel.moderationStatus === 'approved' ? 'Одобрил' : 'Отклонил'}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <PersonIcon sx={{ fontSize: '1rem', color: 'text.secondary' }} />
                              <Typography variant="body1">
                                {selectedChannel.moderatedBy?.username}
                              </Typography>
                            </Box>
                            {selectedChannel.moderatedAt && (
                              <Typography variant="caption" color="text.secondary">
                                {new Date(selectedChannel.moderatedAt).toLocaleDateString()}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Box>

                    {/* Быстрые действия */}
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
                        Действия
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Button
                          fullWidth
                          variant="outlined"
                          startIcon={<TelegramIcon />}
                          href={selectedChannel.link}
                          target="_blank"
                          sx={{ justifyContent: 'flex-start' }}
                        >
                          Открыть канал
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          color={selectedChannel.isPremium ? "warning" : "primary"}
                          onClick={() => handleTogglePremium(selectedChannel._id)}
                          startIcon={<WorkspacePremiumIcon />}
                          sx={{ justifyContent: 'flex-start' }}
                        >
                          {selectedChannel.isPremium ? 'Убрать премиум' : 'Сделать премиум'}
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          color={selectedChannel.isRecommended ? "info" : "primary"}
                          onClick={() => handleToggleRecommended(selectedChannel._id)}
                          startIcon={<StarIcon />}
                          sx={{ justifyContent: 'flex-start' }}
                        >
                          {selectedChannel.isRecommended ? 'Убрать из рекомендаций' : 'В рекомендации'}
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            setChannelToDelete(selectedChannel._id);
                            setConfirmDelete(true);
                          }}
                          startIcon={<DeleteIcon />}
                          sx={{ justifyContent: 'flex-start' }}
                        >
                          Удалить канал
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                {/* Правая колонка */}
                <Grid item xs={12} md={8}>
                  <Box sx={{ p: 3 }}>
                    {/* Описание */}
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
                        Описание
                      </Typography>
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 2,
                          bgcolor: 'background.default',
                          minHeight: '100px',
                          maxHeight: '150px',
                          overflow: 'auto'
                        }}
                      >
                        <Typography variant="body2">
                          {selectedChannel.description || 'Нет описания'}
                        </Typography>
                      </Paper>
                    </Box>

                    {/* Категории */}
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
                        Категории
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selectedChannel.categories?.map((category) => (
                          <Chip
                            key={category}
                            label={category}
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        ))}
                      </Box>
                    </Box>

                    {/* Модерация */}
                    {selectedChannel.moderationStatus === 'pending' && (
                      <Box sx={{ mt: 3 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
                          Модерация
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => handleApprove(selectedChannel._id)}
                            startIcon={<CheckCircle />}
                            fullWidth
                          >
                            Одобрить
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleReject(selectedChannel._id)}
                            startIcon={<Cancel />}
                            fullWidth
                          >
                            Отклонить
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions sx={{ p: 2, bgcolor: 'background.default', borderTop: 1, borderColor: 'divider' }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => setOpenDialog(false)}
              >
                Закрыть
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <DeleteConfirmationModal
        show={confirmDelete}
        onHide={() => setConfirmDelete(false)}
        onConfirm={handleConfirmDelete}
        title="Подтверждение удаления канала"
        message="Вы уверены, что хотите удалить этот канал? Это действие нельзя отменить."
      />
    </Box>
  );
};

export default ChannelModeration;
