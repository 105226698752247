import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Save as SaveIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';

// Моковые данные
const mockMetaTags = {
  home: {
    title: 'Каталог Telegram каналов | Главная',
    description: 'Лучший каталог Telegram каналов. Находите интересные каналы по категориям, добавляйте свои каналы.',
    keywords: 'telegram, каналы, каталог, поиск каналов, категории'
  },
  channels: {
    title: 'Все каналы | Каталог Telegram',
    description: 'Полный список Telegram каналов в нашем каталоге. Сортировка по популярности и категориям.',
    keywords: 'telegram каналы, список каналов, популярные каналы'
  }
};

const mockSitemapSettings = {
  includeChannels: true,
  includeCategories: true,
  includeArticles: true,
  lastUpdate: '2023-12-14T10:30:00'
};

const mockRobotsTxt = `User-agent: *
Allow: /
Disallow: /admin/
Disallow: /api/
Disallow: /private/

User-agent: Googlebot
Allow: /
Disallow: /admin/

Sitemap: https://yoursite.com/sitemap.xml`;

const mockRedirects = [
  { id: 1, fromUrl: '/old-channels', toUrl: '/channels', type: 301 },
  { id: 2, fromUrl: '/blog', toUrl: '/articles', type: 301 },
  { id: 3, fromUrl: '/temp-page', toUrl: '/new-page', type: 302 }
];

const SEO = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [metaTags, setMetaTags] = useState(mockMetaTags);
  const [sitemapSettings, setSitemapSettings] = useState(mockSitemapSettings);
  const [robotsTxt, setRobotsTxt] = useState(mockRobotsTxt);
  const [redirects, setRedirects] = useState(mockRedirects);
  const [selectedPage, setSelectedPage] = useState('home');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [isAddRedirectOpen, setIsAddRedirectOpen] = useState(false);
  const [newRedirect, setNewRedirect] = useState({ fromUrl: '', toUrl: '', type: 301 });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleMetaTagsChange = (field, value) => {
    setMetaTags(prev => ({
      ...prev,
      [selectedPage]: {
        ...prev[selectedPage],
        [field]: value
      }
    }));
  };

  const handleSitemapToggle = (setting) => {
    setSitemapSettings(prev => ({
      ...prev,
      [setting]: !prev[setting]
    }));
  };

  const generateSitemap = () => {
    setSitemapSettings(prev => ({
      ...prev,
      lastUpdate: new Date().toISOString()
    }));
    showNotification('Sitemap успешно обновлен', 'success');
  };

  const handleRobotsTxtChange = (event) => {
    setRobotsTxt(event.target.value);
  };

  const saveRobotsTxt = () => {
    showNotification('Robots.txt успешно обновлен', 'success');
  };

  const addRedirect = () => {
    if (newRedirect.fromUrl && newRedirect.toUrl) {
      setRedirects(prev => [...prev, { ...newRedirect, id: Date.now() }]);
      setIsAddRedirectOpen(false);
      setNewRedirect({ fromUrl: '', toUrl: '', type: 301 });
      showNotification('Редирект успешно добавлен', 'success');
    }
  };

  const deleteRedirect = (id) => {
    setRedirects(prev => prev.filter(redirect => redirect.id !== id));
    showNotification('Редирект удален', 'success');
  };

  const showNotification = (message, severity = 'success') => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('ru-RU');
  };

  return (
    <>
      <Helmet>
        <title>Управление SEO | Админ панель</title>
      </Helmet>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h5" gutterBottom>
            Управление SEO
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="Мета-теги" />
              <Tab label="Sitemap" />
              <Tab label="Robots.txt" />
              <Tab label="Редиректы" />
            </Tabs>
          </Box>

          {/* Мета-теги */}
          {activeTab === 0 && (
            <Box>
              <Box sx={{ mb: 2 }}>
                <Select
                  value={selectedPage}
                  onChange={(e) => setSelectedPage(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="home">Главная страница</MenuItem>
                  <MenuItem value="channels">Страница каналов</MenuItem>
                </Select>
              </Box>
              <TextField
                fullWidth
                label="Title"
                value={metaTags[selectedPage].title}
                onChange={(e) => handleMetaTagsChange('title', e.target.value)}
                helperText="Заголовок страницы в поисковой выдаче (до 60 символов)"
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={3}
                value={metaTags[selectedPage].description}
                onChange={(e) => handleMetaTagsChange('description', e.target.value)}
                helperText="Описание страницы в поисковой выдаче (до 160 символов)"
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Keywords"
                value={metaTags[selectedPage].keywords}
                onChange={(e) => handleMetaTagsChange('keywords', e.target.value)}
                helperText="Ключевые слова через запятую"
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() => showNotification('Мета-теги сохранены')}
              >
                Сохранить
              </Button>
            </Box>
          )}

          {/* Sitemap */}
          {activeTab === 1 && (
            <Box>
              <FormGroup sx={{ mb: 3 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={sitemapSettings.includeChannels}
                      onChange={() => handleSitemapToggle('includeChannels')}
                    />
                  }
                  label="Включать страницы каналов"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={sitemapSettings.includeCategories}
                      onChange={() => handleSitemapToggle('includeCategories')}
                    />
                  }
                  label="Включать страницы категорий"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={sitemapSettings.includeArticles}
                      onChange={() => handleSitemapToggle('includeArticles')}
                    />
                  }
                  label="Включать статьи"
                />
              </FormGroup>
              <Button
                variant="contained"
                startIcon={<RefreshIcon />}
                onClick={generateSitemap}
                sx={{ mb: 2 }}
              >
                Обновить Sitemap
              </Button>
              <Typography variant="body2" color="text.secondary">
                Последнее обновление: {formatDate(sitemapSettings.lastUpdate)}
              </Typography>
            </Box>
          )}

          {/* Robots.txt */}
          {activeTab === 2 && (
            <Box>
              <TextField
                fullWidth
                multiline
                rows={10}
                value={robotsTxt}
                onChange={handleRobotsTxtChange}
                sx={{ mb: 2 }}
                InputProps={{
                  style: { fontFamily: 'monospace' }
                }}
              />
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={saveRobotsTxt}
              >
                Сохранить
              </Button>
            </Box>
          )}

          {/* Редиректы */}
          {activeTab === 3 && (
            <Box>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setIsAddRedirectOpen(true)}
                sx={{ mb: 2 }}
              >
                Добавить редирект
              </Button>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Старый URL</TableCell>
                      <TableCell>Новый URL</TableCell>
                      <TableCell>Тип</TableCell>
                      <TableCell>Действия</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {redirects.map((redirect) => (
                      <TableRow key={redirect.id}>
                        <TableCell>{redirect.fromUrl}</TableCell>
                        <TableCell>{redirect.toUrl}</TableCell>
                        <TableCell>
                          <Select
                            value={redirect.type}
                            size="small"
                            onChange={(e) => {
                              const newRedirects = redirects.map(r =>
                                r.id === redirect.id ? { ...r, type: e.target.value } : r
                              );
                              setRedirects(newRedirects);
                            }}
                          >
                            <MenuItem value={301}>301 (Постоянный)</MenuItem>
                            <MenuItem value={302}>302 (Временный)</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            color="error"
                            onClick={() => deleteRedirect(redirect.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Paper>

        {/* Диалог добавления редиректа */}
        <Dialog
          open={isAddRedirectOpen}
          onClose={() => setIsAddRedirectOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Добавить редирект</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Старый URL"
              value={newRedirect.fromUrl}
              onChange={(e) => setNewRedirect({ ...newRedirect, fromUrl: e.target.value })}
              sx={{ mt: 2, mb: 2 }}
            />
            <TextField
              fullWidth
              label="Новый URL"
              value={newRedirect.toUrl}
              onChange={(e) => setNewRedirect({ ...newRedirect, toUrl: e.target.value })}
              sx={{ mb: 2 }}
            />
            <Select
              fullWidth
              value={newRedirect.type}
              onChange={(e) => setNewRedirect({ ...newRedirect, type: e.target.value })}
            >
              <MenuItem value={301}>301 (Постоянный)</MenuItem>
              <MenuItem value={302}>302 (Временный)</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsAddRedirectOpen(false)}>Отмена</Button>
            <Button variant="contained" onClick={addRedirect}>Добавить</Button>
          </DialogActions>
        </Dialog>

        {/* Уведомления */}
        <Snackbar
          open={notification.open}
          autoHideDuration={3000}
          onClose={handleCloseNotification}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default SEO;
