import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Grid,
  Stack,
  Link,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BlockIcon from '@mui/icons-material/Block';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import TelegramIcon from '@mui/icons-material/Telegram';
import PendingIcon from '@mui/icons-material/Pending';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RouterIcon from '@mui/icons-material/Router';
import WebIcon from '@mui/icons-material/Web';
import { Helmet } from 'react-helmet-async';
import api from '../../services/api';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [channelDialogOpen, setChannelDialogOpen] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/admin/users');

      // Проверяем наличие данных в ответе
      if (response && response.data) {
        setUsers(response.data.users || []);
        setError('');
      } else {
        throw new Error('Некорректный ответ от сервера');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Произошла ошибка при загрузке пользователей');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users.filter(user =>
    (user.username?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  );

  const toggleUserStatus = async (userId) => {
    try {
      await api.patch(`/api/admin/users/${userId}/toggle-status`);
      fetchUsers();
      
      // Обновляем информацию в диалоговом окне, если оно открыто
      if (selectedUser && selectedUser._id === userId) {
        const userResponse = await api.get(`/api/admin/users/${userId}`);
        if (userResponse.ok) {
          const data = await userResponse.data;
          setSelectedUser(data.user);
        }
      }
    } catch (error) {
      console.error('Error toggling user status:', error);
      setError('Произошла ошибка при изменении статуса пользователя');
    }
  };

  const handleUserClick = async (userId) => {
    try {
      const response = await api.get(`/api/admin/users/${userId}`);

      // Проверяем наличие данных в ответе
      if (response && response.data && response.data.user) {
        setSelectedUser(response.data.user);
        setDialogOpen(true);
      } else {
        throw new Error('Некорректный ответ от сервера');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError('Произошла ошибка при получении информации о пользователе');
    }
  };

  const toggleAdmin = async (userId) => {
    try {
      await api.patch(`/api/admin/users/${userId}/toggle-admin`);
      fetchUsers();
      
      // Обновляем информацию в диалоговом окне, если оно открыто
      if (selectedUser && selectedUser._id === userId) {
        const userResponse = await api.get(`/api/admin/users/${userId}`);
        if (userResponse.ok) {
          const data = await userResponse.data;
          setSelectedUser(data.user);
        }
      }
    } catch (error) {
      console.error('Error toggling admin status:', error);
      setError('Произошла ошибка при изменении статуса администратора');
    }
  };

  const changeUserPassword = async (userId) => {
    try {
      if (!newPassword) {
        setPasswordError('Пароль не может быть пустым');
        return;
      }

      const response = await api.patch(`/api/admin/users/${userId}/change-password`, {
        newPassword
      });

      if (!response.ok) {
        throw new Error(response.data.message || 'Ошибка при смене пароля');
      }

      setNewPassword('');
      setShowPassword(false);
      setPasswordError('');
      setError('Пароль успешно изменен');
      setTimeout(() => setError(''), 3000);
    } catch (err) {
      setError(err.message || 'Произошла ошибка при смене пароля');
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Функция для форматирования IP-адреса
  const formatIpAddress = (ip) => {
    if (!ip) return 'Неизвестно';
    if (ip === '::1' || ip === '127.0.0.1') return 'Локальный доступ';
    if (ip.includes('::ffff:')) return ip.replace('::ffff:', '');
    return ip;
  };

  return (
    <>
      <Helmet>
        <title>Управление пользователями | Admin Dashboard</title>
      </Helmet>
      
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
          Управление пользователями
        </Typography>

        {/* Search and filters */}
        <Paper sx={{ p: 2, mb: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Поиск по имени пользователя..."
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <SearchIcon />
                </Box>
              ),
            }}
            sx={{ mb: 2 }}
          />
        </Paper>

        {error && (
          <Alert 
            severity={error === 'Пароль успешно изменен' ? 'success' : 'error'} 
            sx={{ mb: 3 }}
            onClose={() => setError('')}
          >
            {error}
          </Alert>
        )}

        <Paper>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Имя пользователя</TableCell>
                      <TableCell>Роль</TableCell>
                      <TableCell>Каналов</TableCell>
                      <TableCell>Статус</TableCell>
                      <TableCell>Дата регистрации</TableCell>
                      <TableCell>Действия</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((user) => (
                        <TableRow key={user._id}>
                          <TableCell>{user.username}</TableCell>
                          <TableCell>
                            <Chip
                              label={user.isAdmin ? 'Администратор' : 'Пользователь'}
                              color={user.isAdmin ? 'primary' : 'default'}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>{user.channels?.length || 0}</TableCell>
                          <TableCell>
                            <Chip
                              label={user.isActive ? 'Активен' : 'Заблокирован'}
                              color={user.isActive ? 'success' : 'error'}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>{formatDate(user.createdAt)}</TableCell>
                          <TableCell>
                            <Button
                              color="primary"
                              onClick={() => handleUserClick(user._id)}
                              sx={{ mr: 1 }}
                              startIcon={<InfoIcon />}
                            >
                              Информация
                            </Button>
                            {!user.isAdmin && (
                              <Button
                                color={user.isActive ? 'error' : 'success'}
                                onClick={() => toggleUserStatus(user._id)}
                                startIcon={user.isActive ? <BlockIcon /> : <CheckCircleIcon />}
                              >
                                {user.isActive ? 'Заблокировать' : 'Разблокировать'}
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={filteredUsers.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
                labelRowsPerPage="Строк на странице:"
              />
            </>
          )}
        </Paper>
      </Box>

      {/* Диалоговое окно с подробной информацией */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedUser && (
          <>
            <DialogTitle sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              bgcolor: 'primary.main',
              color: 'white'
            }}>
              <InfoIcon />
              Информация о пользователе {selectedUser.username}
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
              <Grid container spacing={3}>
                {/* Основная информация */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default' }}>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <PersonOffIcon /> Основная информация
                    </Typography>
                    <List disablePadding>
                      <ListItem>
                        <ListItemText
                          primary="Имя пользователя"
                          secondary={selectedUser.username}
                          primaryTypographyProps={{ color: 'text.secondary' }}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Дата регистрации"
                          secondary={formatDate(selectedUser.createdAt)}
                          primaryTypographyProps={{ color: 'text.secondary' }}
                        />
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>

                {/* Статус и роль */}
                <Grid item xs={12} md={6}>
                  <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default' }}>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AdminPanelSettingsIcon /> Статус и роль
                    </Typography>
                    <Stack spacing={2}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Chip
                          icon={selectedUser.isAdmin ? <AdminPanelSettingsIcon /> : <PersonOffIcon />}
                          label={selectedUser.isAdmin ? 'Администратор' : 'Пользователь'}
                          color={selectedUser.isAdmin ? 'primary' : 'default'}
                        />
                        <Button
                          variant="outlined"
                          startIcon={selectedUser.isAdmin ? <PersonOffIcon /> : <AdminPanelSettingsIcon />}
                          onClick={() => toggleAdmin(selectedUser._id)}
                          color={selectedUser.isAdmin ? 'error' : 'primary'}
                          size="small"
                        >
                          {selectedUser.isAdmin ? 'Снять админа' : 'Сделать админом'}
                        </Button>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Chip
                          icon={selectedUser.isActive ? <CheckCircleIcon /> : <BlockIcon />}
                          label={selectedUser.isActive ? 'Активен' : 'Заблокирован'}
                          color={selectedUser.isActive ? 'success' : 'error'}
                        />
                        {!selectedUser.isAdmin && (
                          <Button
                            variant="outlined"
                            startIcon={selectedUser.isActive ? <BlockIcon /> : <CheckCircleIcon />}
                            onClick={() => toggleUserStatus(selectedUser._id)}
                            color={selectedUser.isActive ? 'error' : 'success'}
                            size="small"
                          >
                            {selectedUser.isActive ? 'Заблокировать' : 'Разблокировать'}
                          </Button>
                        )}
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>

                {/* Изменение пароля */}
                <Grid item xs={12}>
                  <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default' }}>
                    <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <LockIcon /> Изменение пароля
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <FormControl variant="outlined" fullWidth error={!!passwordError}>
                        <InputLabel htmlFor="new-password">Новый пароль</InputLabel>
                        <OutlinedInput
                          id="new-password"
                          type={showPassword ? 'text' : 'password'}
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          endAdornment={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Button
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                size="small"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </Button>
                            </Box>
                          }
                          label="Новый пароль"
                        />
                        {passwordError && (
                          <Typography color="error" variant="caption">
                            {passwordError}
                          </Typography>
                        )}
                      </FormControl>
                      <Button
                        variant="contained"
                        onClick={() => changeUserPassword(selectedUser._id)}
                        disabled={!newPassword}
                      >
                        Изменить пароль
                      </Button>
                    </Box>
                  </Paper>
                </Grid>

                {/* Последний вход */}
                {selectedUser.lastLogin && (
                  <Grid item xs={12}>
                    <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default' }}>
                      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccessTimeIcon /> Последний вход
                      </Typography>
                      <List disablePadding>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography color="text.secondary" gutterBottom>
                                Дата и время входа
                              </Typography>
                            }
                            secondary={formatDate(selectedUser.lastLogin.timestamp)}
                          />
                        </ListItem>
                        {selectedUser.lastLogin.ip && (
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography color="text.secondary" gutterBottom>
                                  IP-адрес
                                </Typography>
                              }
                              secondary={
                                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                                  <RouterIcon fontSize="small" />
                                  {formatIpAddress(selectedUser.lastLogin.ip)}
                                </Typography>
                              }
                            />
                          </ListItem>
                        )}
                        {selectedUser.lastLogin.userAgent && (
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography color="text.secondary" gutterBottom>
                                  Информация о браузере
                                </Typography>
                              }
                              secondary={selectedUser.lastLogin.userAgent}
                            />
                          </ListItem>
                        )}
                      </List>
                    </Paper>
                  </Grid>
                )}

                {/* Каналы пользователя */}
                {selectedUser.channels?.length > 0 && (
                  <Grid item xs={12}>
                    <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default' }}>
                      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <TelegramIcon /> Каналы пользователя ({selectedUser.channels.length})
                      </Typography>
                      <Grid container spacing={2}>
                        {selectedUser.channels.map((channel, index) => (
                          <Grid item xs={12} sm={6} md={4} key={channel._id}>
                            <Paper 
                              elevation={1} 
                              sx={{ 
                                p: 2, 
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                                transition: 'all 0.2s',
                                '&:hover': {
                                  bgcolor: 'action.hover',
                                  transform: 'translateY(-2px)',
                                  boxShadow: 2
                                }
                              }}
                              onClick={() => {
                                setSelectedChannel(channel);
                                setChannelDialogOpen(true);
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1, minWidth: 0 }}>
                                <TelegramIcon color="primary" />
                                <Typography noWrap title={channel.name}>
                                  {channel.name}
                                </Typography>
                              </Box>
                              <Chip
                                size="small"
                                sx={{ ml: 1, minWidth: 'auto' }}
                                icon={
                                  channel.moderationStatus === 'approved' ? <CheckCircleIcon /> :
                                  channel.moderationStatus === 'rejected' ? <BlockIcon /> :
                                  <PendingIcon />
                                }
                                color={
                                  channel.moderationStatus === 'approved' ? 'success' :
                                  channel.moderationStatus === 'rejected' ? 'error' :
                                  'warning'
                                }
                              />
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2, bgcolor: 'background.paper' }}>
              <Button 
                variant="contained"
                onClick={() => {
                  setDialogOpen(false);
                  setNewPassword('');
                  setPasswordError('');
                  setShowPassword(false);
                }}
              >
                Закрыть
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Диалоговое окно с информацией о канале */}
      <Dialog
        open={channelDialogOpen}
        onClose={() => setChannelDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        TransitionProps={{
          onExited: () => setSelectedChannel(null)
        }}
      >
        {selectedChannel && (
          <>
            <DialogTitle sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              bgcolor: 'primary.main',
              color: 'white'
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TelegramIcon />
                <Typography variant="h6" component="span" noWrap sx={{ flex: 1 }}>
                  {selectedChannel.name}
                </Typography>
              </Box>
              <Chip
                size="small"
                sx={{ color: 'white' }}
                icon={
                  selectedChannel.moderationStatus === 'approved' ? <CheckCircleIcon /> :
                  selectedChannel.moderationStatus === 'rejected' ? <BlockIcon /> :
                  <PendingIcon />
                }
                label={
                  selectedChannel.moderationStatus === 'approved' ? 'Одобрен' :
                  selectedChannel.moderationStatus === 'rejected' ? 'Отклонен' :
                  'На модерации'
                }
                color={
                  selectedChannel.moderationStatus === 'approved' ? 'success' :
                  selectedChannel.moderationStatus === 'rejected' ? 'error' :
                  'warning'
                }
              />
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default' }}>
                    <List disablePadding>
                      <ListItem disablePadding sx={{ mb: 2 }}>
                        <ListItemText
                          primary={
                            <Typography color="text.secondary" gutterBottom>
                              Ссылка на канал
                            </Typography>
                          }
                          secondary={
                            <Link 
                              href={selectedChannel.link} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 0.5,
                                color: 'primary.main',
                                textDecoration: 'none',
                                '&:hover': {
                                  textDecoration: 'underline'
                                }
                              }}
                            >
                              {selectedChannel.link}
                              <OpenInNewIcon fontSize="small" />
                            </Link>
                          }
                        />
                      </ListItem>

                      <ListItem disablePadding sx={{ mb: 2 }}>
                        <ListItemText
                          primary={
                            <Typography color="text.secondary" gutterBottom>
                              Дата добавления
                            </Typography>
                          }
                          secondary={formatDate(selectedChannel.createdAt)}
                        />
                      </ListItem>

                      {selectedChannel.subscribers > 0 && (
                        <ListItem disablePadding sx={{ mb: 2 }}>
                          <ListItemText
                            primary={
                              <Typography color="text.secondary" gutterBottom>
                                Подписчики
                              </Typography>
                            }
                            secondary={`${selectedChannel.subscribers.toLocaleString()} ${
                              selectedChannel.subscribers % 10 === 1 && selectedChannel.subscribers % 100 !== 11 
                                ? 'подписчик' 
                                : selectedChannel.subscribers % 10 >= 2 && selectedChannel.subscribers % 10 <= 4 
                                  && (selectedChannel.subscribers % 100 < 10 || selectedChannel.subscribers % 100 >= 20) 
                                  ? 'подписчика' 
                                  : 'подписчиков'
                            }`}
                          />
                        </ListItem>
                      )}

                      {selectedChannel.description && (
                        <ListItem disablePadding sx={{ mb: 2 }}>
                          <ListItemText
                            primary={
                              <Typography color="text.secondary" gutterBottom>
                                Описание
                              </Typography>
                            }
                            secondary={selectedChannel.description}
                          />
                        </ListItem>
                      )}

                      {selectedChannel.categories?.length > 0 && (
                        <ListItem disablePadding>
                          <ListItemText
                            primary={
                              <Typography color="text.secondary" gutterBottom>
                                Категории
                              </Typography>
                            }
                            secondary={
                              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                {selectedChannel.categories.map((category, index) => (
                                  <Chip
                                    key={index}
                                    label={category}
                                    size="small"
                                    color="default"
                                  />
                                ))}
                              </Box>
                            }
                          />
                        </ListItem>
                      )}
                    </List>
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2, bgcolor: 'background.paper', gap: 1 }}>
              <Button 
                onClick={() => setChannelDialogOpen(false)}
                color="inherit"
              >
                Закрыть
              </Button>
              <Button 
                variant="contained" 
                color="primary"
                href={selectedChannel.link}
                target="_blank"
                rel="noopener noreferrer"
                startIcon={<TelegramIcon />}
              >
                Открыть в Telegram
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default Users;
