import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Chip,
  IconButton,
  CircularProgress,
  LinearProgress,
  useTheme
} from '@mui/material';
import {
  TrendingUp,
  NotificationsActive,
  Warning,
  CheckCircle,
  Person as PersonIcon,
  People as PeopleIcon,
  QuestionAnswer,
  AccessTime as AccessTimeIcon,
  AllInbox as AllInboxIcon,
  NotificationsOff as NotificationsOffIcon,
  ExpandLess,
  ExpandMore,
  Refresh as RefreshIcon,
  AdminPanelSettings as ModeratorIcon,
  Article as ArticleIcon
} from '@mui/icons-material';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { getCsrfToken } from '../../utils/csrfUtils';

const AdminStats = () => {
  const theme = useTheme();
  const [stats, setStats] = useState(null);
  const [recentActivities, setRecentActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [systemInfo, setSystemInfo] = useState({
    status: 'active',
    load: 'normal',
    lastUpdate: new Date().toISOString(),
    uptime: 0,
    totalUsers: 0,
    newUsersToday: 0,
    systemLoad: 0,
    loadStatus: 'low'
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchStats();
    fetchSystemInfo();

    // Обновляем системную информацию каждую минуту
    const interval = setInterval(fetchSystemInfo, 60000);
    return () => clearInterval(interval);
  }, []);

  const fetchSystemInfo = async () => {
    try {
      // Получаем CSRF токен для запроса
      const csrfToken = getCsrfToken();
      
      const response = await fetch('/api/stats/system', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-CSRF-Token': csrfToken
        }
      });
      
      if (!response.ok) {
        throw new Error(`Ошибка при получении системной информации: ${response.status}`);
      }
      
      const data = await response.json();
      setSystemInfo(data);
    } catch (error) {
      console.error('Ошибка при получении системной информации:', error);
    }
  };

  const fetchStats = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Получаем CSRF токен для запроса
      const csrfToken = getCsrfToken();
      
      // Получаем статистику
      const statsResponse = await fetch('/api/stats/admin', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-CSRF-Token': csrfToken
        }
      });
      
      if (!statsResponse.ok) {
        throw new Error(`Ошибка при получении статистики: ${statsResponse.status}`);
      }
      
      const statsData = await statsResponse.json();
      setStats(statsData);
      
      // Получаем последние действия
      const activitiesResponse = await fetch('/api/stats/activities', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-CSRF-Token': csrfToken
        }
      });
      
      if (!activitiesResponse.ok) {
        throw new Error(`Ошибка при получении последних действий: ${activitiesResponse.status}`);
      }
      
      const activitiesData = await activitiesResponse.json();
      setRecentActivities(activitiesData);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    fetchStats();
    fetchSystemInfo();
  };

  const formatUptime = (seconds) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    if (days > 0) {
      return `${days}д ${hours}ч ${minutes}м`;
    } else if (hours > 0) {
      return `${hours}ч ${minutes}м`;
    } else {
      return `${minutes}м`;
    }
  };

  // Компонент для отображения статистической карточки
  const StatCard = ({ title, value, icon, secondary, sx }) => {
    return (
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          height: 200, // Увеличена высота карточек
          borderRadius: 2,
          transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',
          '&:hover': {
            boxShadow: (theme) => theme.shadows[4],
            transform: 'translateY(-4px)',
          },
          ...sx,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography color="textSecondary" variant="h6">
            {title}
          </Typography>
          <Box sx={{ color: 'primary.main' }}>
            {icon}
          </Box>
        </Box>
        <Typography variant="h4" component="div" sx={{ color: 'primary.main', mb: 'auto' }}>
          {value}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          {secondary && (
            <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
              {secondary}
            </Typography>
          )}
        </Box>
      </Paper>
    );
  };

  const ActivityItem = ({ activity }) => {
    const theme = useTheme();

    const getIcon = () => {
      switch (activity.type) {
        case 'channel':
          return <ModeratorIcon color={activity.status === 'approved' ? "success" : "warning"} />;
        case 'question':
          return <QuestionAnswer color={activity.status === 'answered' ? "success" : "info"} />;
        case 'user':
          return <PersonIcon color="primary" />;
        default:
          return <NotificationsActive color="action" />;
      }
    };

    const getTimeString = (time) => {
      const date = new Date(time);
      const now = new Date();
      const diff = now - date;
      const minutes = Math.floor(diff / 60000);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (minutes < 60) {
        return `${minutes} мин. назад`;
      } else if (hours < 24) {
        return `${hours} ч. назад`;
      } else if (days < 7) {
        return `${days} дн. назад`;
      } else {
        return date.toLocaleDateString('ru-RU', { 
          day: 'numeric',
          month: 'short'
        });
      }
    };

    return (
      <Box 
        sx={{ 
          p: 2, 
          mb: 1, 
          display: 'flex', 
          alignItems: 'center',
          borderRadius: 1,
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }}
      >
        <Box sx={{ mr: 2, color: 'primary.main' }}>
          {getIcon()}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
            {activity.message}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {getTimeString(activity.time)}
          </Typography>
        </Box>
      </Box>
    );
  };

  const RecentActivities = ({ activities, onRefresh }) => {
    const [filter, setFilter] = useState('all');
    const [expanded, setExpanded] = useState(false);
    
    // Фильтруем активности по типу
    const filteredActivities = activities.filter(activity => {
      if (filter === 'all') return true;
      return activity.type === filter;
    });
    
    // Подсчитываем количество активностей по типам
    const counts = activities.reduce((acc, activity) => {
      acc[activity.type] = (acc[activity.type] || 0) + 1;
      return acc;
    }, {});
    
    // Определяем, сколько активностей показывать
    const displayedActivities = expanded 
      ? filteredActivities 
      : filteredActivities.slice(0, 3);

    return (
      <Paper 
        sx={{ 
          p: 3, 
          borderRadius: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 3
        }}>
          <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
            Последние действия
          </Typography>
          <IconButton 
            onClick={onRefresh} 
            size="small"
            color="primary"
          >
            <RefreshIcon 
              sx={{ 
                animation: loading ? 'spin 1s linear infinite' : 'none',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' }
                }
              }} 
            />
          </IconButton>
        </Box>

        <Box sx={{ mb: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <Chip
            icon={<AllInboxIcon />}
            label={`Все (${activities.length})`}
            onClick={() => setFilter('all')}
            color={filter === 'all' ? 'primary' : 'default'}
            variant={filter === 'all' ? 'filled' : 'outlined'}
          />
          {Object.entries(counts).map(([type, count]) => {
            let icon;
            let label;
            switch (type) {
              case 'channel':
                icon = <ModeratorIcon />;
                label = 'Каналы';
                break;
              case 'question':
                icon = <QuestionAnswer />;
                label = 'Вопросы';
                break;
              case 'user':
                icon = <PersonIcon />;
                label = 'Пользователи';
                break;
              default:
                return null;
            }
            return (
              <Chip
                key={type}
                icon={icon}
                label={`${label} (${count})`}
                onClick={() => setFilter(type)}
                color={filter === type ? 'primary' : 'default'}
                variant={filter === type ? 'filled' : 'outlined'}
              />
            );
          })}
        </Box>

        <Box 
          sx={{ 
            flexGrow: 1,
            overflowY: 'auto',
            mb: 2
          }}
        >
          <TransitionGroup>
            {displayedActivities.map((activity, index) => (
              <CSSTransition
                key={`${activity.id || activity._id || index}`}
                timeout={300}
                classNames="fade"
              >
                <ActivityItem activity={activity} />
              </CSSTransition>
            ))}
          </TransitionGroup>

          {filteredActivities.length === 0 && (
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                py: 4
              }}
            >
              <NotificationsOffIcon sx={{ fontSize: 48, color: 'text.disabled', mb: 2 }} />
              <Typography color="textSecondary" align="center">
                Нет активностей для отображения
              </Typography>
            </Box>
          )}
        </Box>

        {filteredActivities.length > 3 && (
          <Button
            fullWidth
            onClick={() => setExpanded(!expanded)}
            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
          >
            {expanded ? 'Показать меньше' : `Показать все (${filteredActivities.length})`}
          </Button>
        )}
      </Paper>
    );
  };

  const ServerLoadCard = ({ systemLoad, loadStatus }) => {
    // Определяем цвет в зависимости от статуса нагрузки
    const getLoadColor = (status) => {
      switch (status) {
        case 'low':
          return 'success.main';
        case 'medium':
          return 'warning.main';
        case 'high':
          return 'error.main';
        default:
          return 'primary.main';
      }
    };

    // Определяем текст статуса нагрузки
    const getLoadStatusText = (status) => {
      switch (status) {
        case 'low':
          return 'Низкая';
        case 'medium':
          return 'Средняя';
        case 'high':
          return 'Высокая';
        default:
          return 'Неизвестно';
      }
    };

    // Проверяем, что systemLoad определен, иначе используем 0
    const safeSystemLoad = systemLoad !== undefined ? systemLoad : 0;

    // Нормализуем значение нагрузки для прогресс-бара (от 0 до 100)
    const normalizedLoad = Math.min(Math.max(safeSystemLoad / 3 * 100, 0), 100);

    return (
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          height: 200,
          borderRadius: 2,
          transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',
          '&:hover': {
            boxShadow: (theme) => theme.shadows[4],
            transform: 'translateY(-4px)',
          }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography color="textSecondary" variant="h6">
            Нагрузка сервера
          </Typography>
          <Box sx={{ color: getLoadColor(loadStatus) }}>
            <TrendingUp />
          </Box>
        </Box>
        <Typography variant="h4" component="div" sx={{ mb: 2 }}>
          {safeSystemLoad.toFixed(2)}
        </Typography>
        <LinearProgress 
          variant="determinate" 
          value={normalizedLoad} 
          sx={{ 
            mb: 2, 
            height: 8, 
            borderRadius: 4,
            backgroundColor: 'rgba(0,0,0,0.1)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: getLoadColor(loadStatus)
            }
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 'auto' }}>
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: getLoadColor(loadStatus),
              mr: 1
            }}
          />
          <Typography variant="body2" color="textSecondary">
            Статус: {getLoadStatusText(loadStatus)}
          </Typography>
        </Box>
      </Paper>
    );
  };

  if (loading && !stats) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Paper sx={{ p: 3, borderRadius: 2, backgroundColor: '#FFF4F4' }}>
          <Typography color="error" variant="h6" gutterBottom>
            Ошибка при загрузке данных
          </Typography>
          <Typography>{error}</Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleRefresh}
            sx={{ mt: 2 }}
          >
            Попробовать снова
          </Button>
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Первая строка блоков */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Каналов"
            value={stats?.totalChannels || 0}
            icon={<ModeratorIcon />}
            secondary={stats?.newChannelsToday > 0 ? `+${stats.newChannelsToday} сегодня` : null}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Вопросов"
            value={stats?.totalQuestions || 0}
            icon={<QuestionAnswer />}
            secondary={stats?.newQuestionsToday > 0 ? `+${stats.newQuestionsToday} сегодня` : null}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Категорий"
            value={stats?.totalCategories || 0}
            icon={<AllInboxIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Статей"
            value={stats?.totalArticles || 0}
            icon={<ArticleIcon />}
            secondary={stats?.newArticlesToday > 0 ? `+${stats.newArticlesToday} сегодня` : null}
          />
        </Grid>

        {/* Вторая строка блоков */}
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Пользователей"
            value={systemInfo.totalUsers}
            icon={<PeopleIcon />}
            secondary={systemInfo.newUsersToday > 0 ? `+${systemInfo.newUsersToday} сегодня` : null}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Время работы"
            value={formatUptime(systemInfo.uptime)}
            icon={<AccessTimeIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Ответов на вопросы"
            value={stats?.answeredQuestions || 0}
            icon={<QuestionAnswer />}
            secondary={stats?.answeredQuestionsToday > 0 ? `+${stats.answeredQuestionsToday} сегодня` : null}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ServerLoadCard systemLoad={systemInfo.systemLoad} loadStatus={systemInfo.loadStatus} />
        </Grid>
      </Grid>

      {/* Последние действия */}
      <Box sx={{ mt: 3 }}>
        <RecentActivities activities={recentActivities} onRefresh={handleRefresh} />
      </Box>
    </Box>
  );
};

export default AdminStats;
