import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  CircularProgress,
  Snackbar
} from '@mui/material';
import { useAdminAuth } from '../../hooks/useAdminAuth';
import api from '../../services/api';

const TelegramSettings = () => {
  const [config, setConfig] = useState({
    botToken: '',
    status: 'disabled',
    errorMessage: ''
  });
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [testing, setTesting] = useState(false);
  const [testResult, setTestResult] = useState({ success: false, message: '' });

  useEffect(() => {
    fetchTelegramConfig();
  }, []);

  // Получаем токен из localStorage
  const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return token ? { Authorization: `Bearer ${token}` } : {};
  };

  // Получение текущих настроек Telegram
  const fetchTelegramConfig = async () => {
    try {
      setLoading(true);
      // Используем api вместо axios
      const response = await api.get('/api/telegram/config');
      
      if (response.data) {
        setConfig({
          botToken: response.data.botToken || '',
          status: response.data.status || 'disabled',
          errorMessage: response.data.errorMessage || ''
        });
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Ошибка при загрузке настроек Telegram:', error);
      setError('Не удалось загрузить настройки Telegram');
      setLoading(false);
    }
  };

  // Обработчик сохранения настроек
  const handleSaveConfig = async () => {
    try {
      setLoading(true);
      // Используем api вместо axios
      await api.put('/api/telegram/config', {
        botToken: config.botToken
      });
      
      setSuccess('Настройки успешно сохранены');
      setError('');
      setLoading(false);
    } catch (error) {
      console.error('Ошибка при сохранении настроек Telegram:', error);
      setError('Не удалось сохранить настройки Telegram');
      setSuccess('');
      setLoading(false);
    }
  };

  // Обработчик тестирования соединения
  const handleTestConnection = async () => {
    try {
      setTesting(true);
      // Используем api вместо axios
      const response = await api.post('/api/telegram/test-connection');
      
      if (response.data.success) {
        setTestResult({
          success: true,
          message: 'Соединение успешно установлено'
        });
      } else {
        setTestResult({
          success: false,
          message: response.data.message || 'Не удалось установить соединение'
        });
      }
      
      setTesting(false);
    } catch (error) {
      console.error('Ошибка при тестировании соединения Telegram:', error);
      setTestResult({
        success: false,
        message: error.response?.data?.message || 'Не удалось установить соединение'
      });
      setTesting(false);
    }
  };

  const showNotification = (message, severity = 'success') => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Настройки Telegram API
        </Typography>

        {config.status === 'error' && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {config.errorMessage}
          </Alert>
        )}

        {config.status === 'active' && (
          <Alert severity="success" sx={{ mb: 2 }}>
            API успешно подключен
          </Alert>
        )}

        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            label="Bot Token"
            value={config.botToken}
            onChange={(e) => setConfig({ ...config, botToken: e.target.value })}
            type="password"
            helperText="Токен вашего Telegram бота от @BotFather"
            sx={{ mb: 2 }}
          />

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              onClick={handleSaveConfig}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Сохранить'}
            </Button>

            <Button
              variant="outlined"
              onClick={handleTestConnection}
              disabled={loading || !config.botToken}
            >
              Проверить подключение
            </Button>
          </Box>
        </Box>

        <Typography variant="body2" color="text.secondary">
          Для получения Bot Token:
          <ol>
            <li>Откройте @BotFather в Telegram</li>
            <li>Отправьте команду /newbot</li>
            <li>Следуйте инструкциям для создания бота</li>
            <li>Скопируйте полученный токен в это поле</li>
          </ol>
        </Typography>
      </Paper>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TelegramSettings;
