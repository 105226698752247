import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  Home as HomeIcon
} from '@mui/icons-material';
import { updateCategory } from './CategoryService';
import PropTypes from 'prop-types';

/**
 * Компонент таблицы категорий
 * Отображает список категорий с их параметрами и возможностью редактирования
 */
const CategoryTable = ({ categories, onEditCategory, onDeleteCategory, loading, onCategoryUpdated }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateStatus, setUpdateStatus] = useState({ show: false, success: false, message: '' });

  if (loading && !categories.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Отображение цвета категории
  const renderColorBox = (color) => {
    return (
      <Box 
        sx={{
          width: 24,
          height: 24,
          bgcolor: color || '#000000',
          borderRadius: 1,
          border: '1px solid #ddd',
          display: 'inline-block'
        }}
      />
    );
  };

  // Копирование slug в буфер обмена
  const handleCopySlug = (slug) => {
    navigator.clipboard.writeText(slug)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch(err => console.error('Ошибка при копировании:', err));
  };

  // Быстрое переключение флага популярности
  const handleTogglePopular = async (category) => {
    if (updateLoading) return;
    
    setUpdateLoading(true);
    try {
      const updatedData = {
        ...category,
        isPopular: !category.isPopular
      };
      
      // Уведомляем родительский компонент об обновлении
      if (onCategoryUpdated) {
        await onCategoryUpdated(category._id, updatedData);
        
        // Обновляем локальное состояние категории
        category.isPopular = updatedData.isPopular;
      }
      
      setUpdateStatus({
        show: true,
        success: true,
        message: `Категория "${category.name}" ${updatedData.isPopular ? 'добавлена в' : 'удалена из'} популярных`
      });
    } catch (error) {
      console.error('Ошибка при обновлении категории:', error);
      setUpdateStatus({
        show: true,
        success: false,
        message: `Ошибка: ${error.message || 'Не удалось обновить категорию'}`
      });
    } finally {
      setUpdateLoading(false);
      setTimeout(() => setUpdateStatus({ show: false, success: false, message: '' }), 3000);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="250px">Название категории</TableCell>
              <TableCell width="100px" align="center">На главной</TableCell>
              <TableCell width="100px" align="center">Популярная</TableCell>
              <TableCell width="120px" align="center">Slug</TableCell>
              <TableCell width="80px" align="center">Каналов</TableCell>
              <TableCell width="70px" align="right">Действия</TableCell>
              <TableCell width="60px" align="center">Цвет</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories && categories.length > 0 ? (
              categories.map((category) => (
                <TableRow key={category._id} hover>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {category.slug === 'all' ? (
                        <Box 
                          sx={{ 
                            bgcolor: '#1976d2', 
                            color: 'white', 
                            width: 20, 
                            height: 20, 
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,
                            fontSize: '0.75rem',
                            fontWeight: 'bold'
                          }}
                        >
                          A
                        </Box>
                      ) : category.isActive ? (
                        <VisibilityIcon color="success" sx={{ mr: 1 }} fontSize="small" />
                      ) : (
                        <VisibilityOffIcon color="disabled" sx={{ mr: 1 }} fontSize="small" />
                      )}
                      <Typography 
                        sx={{ 
                          fontWeight: category.slug === 'all' ? 'bold' : 'medium',
                          maxWidth: '200px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {category.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    {category.showOnHomepage ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <HomeIcon color="success" fontSize="small" />
                        <Typography variant="body2" sx={{ ml: 1 }}>Да</Typography>
                      </Box>
                    ) : null}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={category.isPopular ? "Удалить из популярных" : "Добавить в популярные"}>
                      <IconButton 
                        size="small" 
                        onClick={() => handleTogglePopular(category)}
                        disabled={updateLoading || loading}
                      >
                        {category.isPopular ? (
                          <StarIcon color="primary" fontSize="small" />
                        ) : (
                          <StarBorderIcon color="action" fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={category.slug}
                      size="small"
                      variant="outlined"
                      sx={{ 
                        height: 24, 
                        fontSize: '0.8rem',
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: '#f5f5f5' }
                      }}
                      onClick={() => handleCopySlug(category.slug)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Chip
                      label={category.channelsCount || 0}
                      size="small"
                      color="default"
                      variant="outlined"
                      sx={{ height: 24, minWidth: 30, fontSize: '0.8rem' }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Редактировать">
                      <IconButton
                        size="small"
                        onClick={() => onEditCategory(category)}
                        disabled={loading}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    {renderColorBox(category.color)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ py: 3 }}>
                  Категории не найдены
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={() => setCopySuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Slug скопирован в буфер обмена
        </Alert>
      </Snackbar>

      <Snackbar
        open={updateStatus.show}
        autoHideDuration={3000}
        onClose={() => setUpdateStatus({ ...updateStatus, show: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity={updateStatus.success ? "success" : "error"} sx={{ width: '100%' }}>
          {updateStatus.message}
        </Alert>
      </Snackbar>
    </>
  );
};

// Пропсы компонента
CategoryTable.propTypes = {
  categories: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onEditCategory: PropTypes.func,
  onDeleteCategory: PropTypes.func,
  onCategoryUpdated: PropTypes.func
};

export default CategoryTable;
