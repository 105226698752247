import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, Paper, Alert, Snackbar } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import CategoryTable from './CategoryTable';
import CategoryModals from './CategoryModals';
import { fetchCategories, createCategory, updateCategory, deleteCategory } from './CategoryService';
import Loader from '../../../components/Loader';

/**
 * Компонент управления категориями
 */
const Categories = () => {
  // Состояние для списка категорий
  const [categories, setCategories] = useState([]);
  
  // Состояния для модальных окон
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  
  // Выбранная категория для редактирования/удаления
  const [selectedCategory, setSelectedCategory] = useState(null);
  
  // Состояния загрузки и ошибок
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);

  /**
   * Загрузка списка категорий
   */
  const loadCategories = useCallback(async () => {
    setIsDataLoading(true);
    setError(null);
    
    try {
      const data = await fetchCategories();
      // Сортировка категорий: сначала категория "all", затем активные, затем по количеству каналов
      const sortedCategories = [...data].sort((a, b) => {
        // Категория "all" всегда в начале
        if (a.slug === 'all') return -1;
        if (b.slug === 'all') return 1;
        
        // Затем сортируем по активности (активные категории в начале)
        if (a.isActive !== b.isActive) {
          return a.isActive ? -1 : 1;
        }
        
        // Затем сортируем по количеству каналов (по убыванию)
        return (b.channelsCount || 0) - (a.channelsCount || 0);
      });
      setCategories(sortedCategories);
      
      // Если есть выбранная категория, обновляем ее данные из полученного списка
      if (selectedCategory) {
        const updatedCategory = sortedCategories.find(c => c._id === selectedCategory._id);
        if (updatedCategory) {
          setSelectedCategory(updatedCategory);
        }
      }
    } catch (error) {
      setError('Не удалось загрузить категории: ' + error.message);
      console.error('Ошибка при загрузке категорий:', error);
    } finally {
      setIsDataLoading(false);
    }
  }, []);

  // Загрузка категорий при монтировании компонента
  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  /**
   * Открытие модального окна добавления категории
   */
  const handleAddCategory = () => {
    setSelectedCategory(null);
    setIsAddModalOpen(true);
  };

  /**
   * Обработчик редактирования категории
   * @param {Object} category - Категория для редактирования
   */
  const handleEditCategory = (category) => {
    // Находим самую актуальную версию категории в списке
    const currentCategory = categories.find(c => c._id === category._id) || category;
    setSelectedCategory(currentCategory);
    setIsEditModalOpen(true);
  };

  /**
   * Обработчик удаления категории
   * @param {Object} category - Категория для удаления
   */
  const handleDeleteCategory = (category) => {
    setSelectedCategory(category);
    setIsDeleteModalOpen(true);
  };

  /**
   * Закрытие всех модальных окон
   */
  const handleCloseModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  /**
   * Обработчик обновления категории
   * @param {string} categoryId - ID категории
   * @param {Object} updatedData - Обновленные данные категории
   * @param {boolean} [closeModals=false] - Закрывать ли модальные окна после обновления
   * @returns {Promise<Object>} Обновленная категория
   */
  const handleUpdateCategory = async (categoryId, updatedData, closeModals = false) => {
    setLoading(true);
    try {
      const updatedCategory = await updateCategory(categoryId, updatedData);
      
      // Обновляем список категорий
      setCategories(prevCategories => 
        prevCategories.map(category => 
          category._id === categoryId ? { ...category, ...updatedData } : category
        )
      );
      
      // Если обновляемая категория выбрана, обновляем и её
      if (selectedCategory && selectedCategory._id === categoryId) {
        setSelectedCategory({ ...selectedCategory, ...updatedData });
      }
      
      // Закрываем модальные окна только если это требуется
      if (closeModals) {
        setIsEditModalOpen(false);
        setIsAddModalOpen(false);
        setIsDeleteModalOpen(false);
        
        setSuccess('Категория успешно обновлена');
      }
      
      return updatedCategory;
    } catch (error) {
      console.error('Ошибка при обновлении категории:', error);
      setError(`Ошибка при обновлении категории: ${error.message}`);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Сохранение категории (создание или обновление)
   * @param {Object} categoryData - Данные категории
   */
  const handleSaveCategory = async (categoryData) => {
    if (selectedCategory) {
      // Обновление существующей категории
      await handleUpdateCategory(selectedCategory._id, categoryData, true);
    } else {
      // Создание новой категории
      setLoading(true);
      try {
        await createCategory(categoryData);
        await loadCategories(); // Перезагружаем список категорий
        
        setIsEditModalOpen(false);
        setIsAddModalOpen(false);
        
        setSuccess('Категория успешно создана');
      } catch (error) {
        console.error('Ошибка при создании категории:', error);
        setError(`Ошибка при создании категории: ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  };

  /**
   * Подтверждение удаления категории
   */
  const handleConfirmDelete = async () => {
    if (!selectedCategory) return;
    
    setLoading(true);
    setError(null);
    
    try {
      await deleteCategory(selectedCategory._id);
      setSuccess('Категория успешно удалена');
      
      // Перезагрузка списка категорий и закрытие модального окна
      await loadCategories();
      handleCloseModal();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Закрытие уведомления об успешном действии
   */
  const handleCloseSuccess = () => {
    setSuccess(null);
  };

  /**
   * Закрытие уведомления об ошибке
   */
  const handleCloseError = () => {
    setError(null);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" component="h1">
          Управление категориями
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddCategory}
          disabled={isDataLoading}
        >
          Добавить категорию
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={handleCloseError}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }} onClose={handleCloseSuccess}>
          {success}
        </Alert>
      )}

      <Paper sx={{ mb: 3 }}>
        {isDataLoading ? (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Loader />
          </Box>
        ) : (
          <CategoryTable 
            categories={categories} 
            loading={isDataLoading}
            onEditCategory={handleEditCategory}
            onDeleteCategory={handleDeleteCategory}
            onCategoryUpdated={handleUpdateCategory}
          />
        )}
      </Paper>

      <CategoryModals
        isAddModalOpen={isAddModalOpen}
        isEditModalOpen={isEditModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        onCloseModal={handleCloseModal}
        onSaveCategory={handleSaveCategory}
        onDeleteCategory={handleConfirmDelete}
        selectedCategory={selectedCategory}
        loading={loading}
      />

      <Snackbar 
        open={!!success} 
        autoHideDuration={5000} 
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Categories;
