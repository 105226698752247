import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Paper,
  Typography,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormHelperText,
  Divider,
  CircularProgress,
  Tooltip
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Image as ImageIcon,
  PushPin as PinIcon,
  PushPinOutlined as UnpinIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import api from '../../services/api';
import MDEditor from '@uiw/react-md-editor';
import { useAdminAuth } from '../../hooks/useAdminAuth';
import ImageUpload from '../../components/ImageUpload';
import ImagePreview from '../../components/ImagePreview';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { secureDelete } from '../../utils/secureDelete';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const categories = [
  'Руководства',
  'Маркетинг',
  'Боты',
  'Автоматизация',
  'Безопасность',
  'Советы',
  'Обзоры',
  'Новости',
];

const ArticleManager = () => {
  useAdminAuth(); // Проверяем права администратора
  const [articles, setArticles] = useState([]); // Инициализируем пустым массивом
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editingArticle, setEditingArticle] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    metaTitle: '',
    metaDescription: '',
    description: '',
    content: '',
    image: '',
    imageAlt: '',
    author: '',
    readTime: '',
    categories: [],
    tags: [], // Убедимся, что tags инициализирован как пустой массив
    keywords: '',
  });
  const [error, setError] = useState('');
  const [availableImages, setAvailableImages] = useState([]);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    fetchArticles();
    fetchAvailableImages();
  }, []);

  const fetchArticles = async () => {
    try {
      setLoading(true);
      setError('');
      const response = await api.get('/api/articles/all');
      setArticles(response.data.articles);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setError(error.response?.data?.message || 'Ошибка при загрузке статей');
      setArticles([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchAvailableImages = async () => {
    try {
      // В реальном приложении здесь будет API-запрос для получения списка доступных изображений
      // Пока используем моковые данные
      setAvailableImages([
        'article-1-800x600.jpg',
        'article-2-800x600.jpg',
        'article-3-800x600.jpg',
      ]);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleOpen = (article = null) => {
    if (article) {
      // Убедимся, что у статьи есть все необходимые поля
      const articleData = {
        ...article,
        tags: article.tags || [],
        categories: article.categories || [],
        metaTitle: article.metaTitle || '',
        metaDescription: article.metaDescription || '',
        content: article.content || '',
        imageAlt: article.imageAlt || '',
        // Извлекаем имя автора из объекта
        author: article.author?.name || '',
        readTime: article.readTime || '',
        keywords: article.keywords || ''
      };
      setEditingArticle(articleData);
      setFormData(articleData);
    } else {
      setEditingArticle(null);
      setFormData({
        title: '',
        metaTitle: '',
        metaDescription: '',
        description: '',
        content: '',
        image: '',
        imageAlt: '',
        author: '',
        readTime: '',
        categories: [],
        tags: [],
        keywords: ''
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingArticle(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContentChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      content: value || '',
    }));
  };

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prev) => ({
      ...prev,
      categories: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleTagsChange = (event) => {
    const { value } = event.target;
    // Преобразуем строку в массив тегов
    const tags = typeof value === 'string' ? value.split(/[,\n]+/) : value;
    
    // Очищаем и форматируем теги
    const formattedTags = tags
      .map(tag => {
        // Убираем пробелы по краям
        tag = tag.trim();
        // Убираем # в начале, если есть
        if (tag.startsWith('#')) {
          tag = tag.substring(1);
        }
        return tag;
      })
      // Фильтруем пустые теги
      .filter(tag => tag.length > 0)
      // Убираем дубликаты и приводим к нижнему регистру
      .filter((tag, index, self) => 
        self.indexOf(tag.toLowerCase()) === index
      );

    setFormData(prev => ({
      ...prev,
      tags: formattedTags
    }));
  };

  const handleImageUpload = (filename) => {
    setFormData((prev) => ({
      ...prev,
      image: filename,
    }));
  };

  const handleImageDelete = () => {
    setFormData((prev) => ({
      ...prev,
      image: '',
      imageAlt: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { ...formData };
      
      // Форматируем теги перед отправкой
      if (data.tags) {
        data.tags = data.tags
          .map(tag => tag.trim())
          .filter(tag => tag.length > 0)
          .map(tag => tag.startsWith('#') ? tag : `#${tag}`);
      }

      // Форматируем автора перед отправкой
      if (data.author) {
        data.author = {
          name: data.author
        };
      }

      if (editingArticle) {
        await api.put(`/api/articles/${editingArticle.slug}`, data);
      } else {
        await api.post('/api/articles', data);
      }
      
      handleClose();
      fetchArticles();
    } catch (err) {
      console.error('Error saving article:', err);
      setError(err.response?.data?.message || 'Ошибка при сохранении статьи');
    }
  };

  const handleDelete = async (slug) => {
    try {
      await secureDelete(`/api/articles/${slug}`);
      fetchArticles();
    } catch (error) {
      // Если требуется подтверждение, показываем модальное окно
      if (error.message === 'CONFIRMATION_REQUIRED') {
        setArticleToDelete(slug);
        setShowDeleteConfirmation(true);
      } else {
        console.error('Error deleting article:', error);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/api/articles/${articleToDelete}`, {
        headers: {
          'X-Confirmation': 'confirm-delete'
        }
      });
      
      setShowDeleteConfirmation(false);
      fetchArticles();
    } catch (error) {
      console.error('Error deleting article after confirmation:', error);
    }
  };

  const handlePin = async (articleId) => {
    try {
      setError('');
      const response = await api.patch(`/api/articles/${articleId}/pin`);
      
      if (response.status === 200) {
        // Обновляем список статей после изменения
        fetchArticles();
      }
    } catch (error) {
      console.error('Error pinning article:', error);
      setError(error.response?.data?.message || 'Ошибка при закреплении статьи');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1">
          Управление статьями
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
          sx={{ borderRadius: 2 }}
        >
          Добавить статью
        </Button>
      </Box>

      {error && (
        <Paper 
          sx={{ 
            p: 2, 
            mb: 3, 
            bgcolor: 'error.light',
            color: 'error.contrastText'
          }}
        >
          <Typography>{error}</Typography>
        </Paper>
      )}

      <Box sx={{ mt: 4 }}>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : articles.length === 0 ? (
          <Typography>Нет статей</Typography>
        ) : (
          articles.map((article) => (
            <StyledPaper key={article._id || article.slug}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6">{article.title}</Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  {article.description}
                </Typography>
                {article.tags && article.tags.length > 0 && (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {article.tags.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        size="small"
                        variant="outlined"
                        color="primary"
                      />
                    ))}
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Tooltip title={article.isPinned ? "Открепить" : "Закрепить"}>
                  <IconButton
                    onClick={() => handlePin(article._id)}
                    size="small"
                    color={article.isPinned ? "primary" : "default"}
                  >
                    {article.isPinned ? <UnpinIcon /> : <PinIcon />}
                  </IconButton>
                </Tooltip>
                <IconButton
                  size="small"
                  onClick={() => handleOpen(article)}
                  title="Редактировать"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => handleDelete(article.slug)}
                  title="Удалить"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </StyledPaper>
          ))
        )}
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          {editingArticle ? 'Редактировать статью' : 'Добавить новую статью'}
        </DialogTitle>
        {error && (
          <Box sx={{ px: 3, py: 1 }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Заголовок"
                fullWidth
                value={formData.title}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="metaTitle"
                label="Meta Title"
                fullWidth
                value={formData.metaTitle}
                onChange={handleChange}
                required
                helperText="SEO заголовок (максимум 60 символов)"
                inputProps={{ maxLength: 60 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="metaDescription"
                label="Meta Description"
                fullWidth
                multiline
                rows={2}
                value={formData.metaDescription}
                onChange={handleChange}
                required
                helperText="SEO описание (максимум 160 символов)"
                inputProps={{ maxLength: 160 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="description"
                label="Описание"
                fullWidth
                multiline
                rows={2}
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Содержание статьи
              </Typography>
              <Typography variant="caption" color="textSecondary" sx={{ mb: 1, display: 'block' }}>
                Минимальная длина: 100 символов. Текущая длина: {formData.content?.length || 0}
              </Typography>
              <MDEditor
                value={formData.content}
                onChange={handleContentChange}
                preview="edit"
                height={400}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Изображение статьи
              </Typography>
              <ImageUpload
                onImageUpload={handleImageUpload}
                onImageDelete={handleImageDelete}
                currentImage={formData.image}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="imageAlt"
                label="Alt-текст изображения"
                fullWidth
                value={formData.imageAlt}
                onChange={handleChange}
                helperText="Описание изображения для SEO и доступности"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Категории</InputLabel>
                <Select
                  multiple
                  value={formData.categories}
                  onChange={handleCategoryChange}
                  input={<OutlinedInput label="Категории" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="tags"
                label="Теги"
                fullWidth
                multiline
                rows={2}
                value={formData.tags.join('\n')}
                onChange={handleTagsChange}
                helperText="Введите теги, каждый с новой строки. Хэштеги (#) добавятся автоматически"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="keywords"
                label="Ключевые слова"
                fullWidth
                value={formData.keywords}
                onChange={handleChange}
                required
                helperText="Ключевые слова через запятую для SEO"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="author"
                label="Автор"
                fullWidth
                value={formData.author}
                onChange={handleChange}
                required
                helperText="Имя автора статьи"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="readTime"
                label="Время чтения"
                fullWidth
                value={formData.readTime}
                onChange={handleChange}
                required
                helperText="Например: 7 min"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingArticle ? 'Сохранить' : 'Создать'}
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmationModal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        onConfirm={handleConfirmDelete}
        title="Подтверждение удаления статьи"
        message="Вы уверены, что хотите удалить эту статью? Это действие нельзя отменить."
      />
    </Box>
  );
};

export default ArticleManager;
