import React from 'react';
import { 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Divider, 
  Box, 
  Toolbar, 
  Typography,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import ModeratorIcon from '@mui/icons-material/AdminPanelSettings';
import QuestionAnswer from '@mui/icons-material/QuestionAnswer';
import PeopleIcon from '@mui/icons-material/People';
import TelegramIcon from '@mui/icons-material/Telegram';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import SettingsIcon from '@mui/icons-material/Settings';

const AdminSidebar = ({ currentSection, setCurrentSection }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const menuItems = [
    { text: 'Статистика', icon: <DashboardIcon />, value: 'dashboard', link: '/admin/dashboard' },
    { text: 'Управление статьями', icon: <ArticleIcon />, value: 'articles', link: '/admin/articles' },
    { text: 'Модерация каналов', icon: <ModeratorIcon />, value: 'channels', link: '/admin/channels' },
    { text: 'Управление категориями', icon: <AllInboxIcon />, value: 'categories', link: '/admin/categories' },
    { text: 'Вопросы пользователей', icon: <QuestionAnswer />, value: 'questions', link: '/admin/questions' },
    { text: 'Пользователи', icon: <PeopleIcon />, value: 'users', link: '/admin/users' },
    { text: 'Настройки Telegram', icon: <TelegramIcon />, value: 'telegram', link: '/admin/telegram' },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Админ-панель
        </Typography>
      </Toolbar>
      <Divider />
      <List sx={{ flexGrow: 1 }}>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.value}
            onClick={() => setCurrentSection(item.value)}
            selected={currentSection === item.value}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        alignItems: 'center',
        opacity: 0.9,
        '@keyframes gradient': {
          '0%': { backgroundPosition: '0% 50%' },
          '50%': { backgroundPosition: '100% 50%' },
          '100%': { backgroundPosition: '0% 50%' }
        }
      }}>
        <SettingsIcon fontSize="small" sx={{ 
          mr: 1,
          background: 'linear-gradient(90deg, #4a90e2, #357abd, #2c5282, #357abd, #4a90e2)',
          backgroundSize: '300% 300%',
          animation: 'gradient 8s ease infinite',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }} />
        <Typography variant="body2" sx={{
          background: 'linear-gradient(90deg, #4a90e2, #357abd, #2c5282, #357abd, #4a90e2)',
          backgroundSize: '300% 300%',
          animation: 'gradient 8s ease infinite',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontWeight: 500
        }}>
          Версия 1.0.0
        </Typography>
      </Box>
    </Box>
  );
};

export default AdminSidebar;
