import React, { useState } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Chip,
  Autocomplete,
  Grid,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';

const CreateArticle = () => {
  const navigate = useNavigate();
  const [article, setArticle] = useState({
    title: '',
    metaTitle: '',
    metaDescription: '',
    description: '',
    content: '',
    image: '',
    imageAlt: '',
    categories: [],
    tags: [],
    keywords: '',
    readTime: ''
  });

  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticle(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleContentChange = (value) => {
    setArticle(prev => ({
      ...prev,
      content: value || ''
    }));
  };

  const handleTagsChange = (event, newValue) => {
    setArticle(prev => ({
      ...prev,
      tags: newValue
    }));
  };

  const handleCategoriesChange = (event, newValue) => {
    setArticle(prev => ({
      ...prev,
      categories: newValue
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/articles', article);
      if (response.data) {
        navigate('/admin/dashboard');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Произошла ошибка при создании статьи');
    }
  };

  const categoryOptions = ['Продвижение', 'Telegram', 'Маркетинг', 'Контент', 'Аналитика'];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Создание новой статьи
        </Typography>
        
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Заголовок"
                name="title"
                value={article.title}
                onChange={handleInputChange}
                required
                helperText="Основной заголовок статьи"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Meta Title"
                name="metaTitle"
                value={article.metaTitle}
                onChange={handleInputChange}
                required
                helperText="SEO заголовок (максимум 60 символов)"
                inputProps={{ maxLength: 60 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Meta Description"
                name="metaDescription"
                value={article.metaDescription}
                onChange={handleInputChange}
                required
                multiline
                rows={2}
                helperText="SEO описание (максимум 160 символов)"
                inputProps={{ maxLength: 160 }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Краткое описание"
                name="description"
                value={article.description}
                onChange={handleInputChange}
                required
                multiline
                rows={2}
                helperText="Краткое описание статьи для превью"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Содержание статьи
              </Typography>
              <MDEditor
                value={article.content}
                onChange={handleContentChange}
                preview="edit"
                height={400}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="URL изображения"
                name="image"
                value={article.image}
                onChange={handleInputChange}
                required
                helperText="URL главного изображения статьи"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Alt текст изображения"
                name="imageAlt"
                value={article.imageAlt}
                onChange={handleInputChange}
                required
                helperText="Описание изображения для SEO"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                options={categoryOptions}
                value={article.categories}
                onChange={handleCategoriesChange}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Категории"
                    helperText="Добавьте категории статьи"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                options={[]}
                value={article.tags}
                onChange={handleTagsChange}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Теги"
                    helperText="Добавьте теги для статьи"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ключевые слова"
                name="keywords"
                value={article.keywords}
                onChange={handleInputChange}
                required
                helperText="Ключевые слова через запятую"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Время чтения"
                name="readTime"
                value={article.readTime}
                onChange={handleInputChange}
                required
                helperText="Например: 7 min"
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Создать статью
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateArticle;
