import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  Typography,
  IconButton
} from '@mui/material';
import { Close as CloseIcon, Delete as DeleteIcon } from '@mui/icons-material';
import CategoryForm from './CategoryForm';

/**
 * Компонент модальных окон для категорий
 */
const CategoryModals = ({
  isAddModalOpen,
  isEditModalOpen,
  isDeleteModalOpen,
  onCloseModal,
  onSaveCategory,
  onDeleteCategory,
  selectedCategory,
  loading
}) => {
  // Определение заголовка модального окна
  const getModalTitle = () => {
    if (isAddModalOpen) return 'Добавление категории';
    if (isEditModalOpen) return 'Редактирование категории';
    if (isDeleteModalOpen) return 'Удаление категории';
    return '';
  };

  // Обработчик отправки формы
  const handleSubmit = (formData) => {
    onSaveCategory(formData);
  };

  // Убеждаемся, что мы всегда используем актуальные данные категории
  const initialValues = selectedCategory ? { ...selectedCategory } : {};

  return (
    <>
      {/* Модальное окно добавления/редактирования категории */}
      <Dialog 
        open={isAddModalOpen || isEditModalOpen} 
        onClose={onCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ pb: 1 }}>
          {getModalTitle()}
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pt: 0 }}>
          <CategoryForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            loading={loading}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2, borderTop: '1px solid #e0e0e0' }}>
          {isEditModalOpen && selectedCategory && selectedCategory.slug !== 'all' && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onDeleteCategory(selectedCategory)}
              disabled={loading}
              sx={{ mr: 'auto' }}
            >
              Удалить
            </Button>
          )}
          
          <Button 
            onClick={onCloseModal} 
            disabled={loading}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            Отмена
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const formSubmitEvent = new Event('submit', { bubbles: true });
              document.querySelector('form').dispatchEvent(formSubmitEvent);
            }}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Сохранение...' : 'Сохранить'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Модальное окно удаления категории */}
      <Dialog 
        open={isDeleteModalOpen} 
        onClose={onCloseModal}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle sx={{ pb: 1 }}>
          {getModalTitle()}
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Вы действительно хотите удалить категорию "{selectedCategory?.name}"?
          </Typography>
          <Typography variant="body2" color="error" sx={{ mb: 2 }}>
            Внимание: Это действие нельзя отменить. Удаление категории может повлиять на отображение каналов.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal} color="primary">
            Отмена
          </Button>
          <Button 
            onClick={() => onDeleteCategory(selectedCategory)} 
            color="error" 
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Удалить'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CategoryModals;
