import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  Container,
  Typography,
  Box,
  Avatar,
  Divider,
  IconButton,
  Skeleton,
  Chip,
  Paper,
  Grid,
  Card,
  CardMedia,
  CardContent
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';

const ArticleImage = styled(motion('img'))(({ theme }) => ({
  width: '100%',
  height: '400px',
  objectFit: 'cover',
  borderRadius: '16px',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    height: '200px',
    borderRadius: '8px',
    marginBottom: theme.spacing(2)
  }
}));

const ArticleHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2)
  }
}));

const AuthorCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    textAlign: 'center',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    '& .MuiAvatar-root': {
      marginRight: 0,
      marginBottom: theme.spacing(1)
    }
  }
}));

const ArticleMetadata = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& > *': {
      width: '100%',
      justifyContent: 'center'
    }
  }
}));

const CategoryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  }
}));

const RelatedArticlesSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(4)
  }
}));

const RelatedArticleCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)'
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(1.5)
    }
  }
}));

const MarkdownContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  '& h1': {
    fontSize: '2.5rem',
    fontWeight: 700,
    marginBottom: '1.5rem',
    marginTop: '2.5rem',
    color: theme.palette.primary.main,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  '& h2': {
    fontSize: '2rem',
    fontWeight: 600,
    marginBottom: '1.25rem',
    marginTop: '2rem',
    color: theme.palette.text.primary
  },
  '& h3': {
    fontSize: '1.75rem',
    fontWeight: 600,
    marginBottom: '1rem',
    marginTop: '1.75rem',
    color: theme.palette.text.primary
  },
  '& p': {
    marginBottom: '1rem',
    lineHeight: 1.7,
    fontSize: '1.1rem'
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  '& blockquote': {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
    fontStyle: 'italic'
  },
  '& code': {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.9em'
  },
  '& pre': {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    overflow: 'auto',
    '& code': {
      backgroundColor: 'transparent',
      padding: 0
    }
  },
  '& ul, & ol': {
    marginBottom: '1.5rem',
    paddingLeft: '2rem',
    '& li': {
      marginBottom: '0.5rem',
      fontSize: '1.1rem',
      lineHeight: 1.7
    }
  },
  '& h1, & h2, & h3': {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    '& h1': { fontSize: '1.75rem' },
    '& h2': { fontSize: '1.5rem' },
    '& h3': { fontSize: '1.25rem' },
    '& p': { fontSize: '1rem' }
  }
}));

const fetchArticle = async (slug) => {
  const response = await fetch(`/api/articles/${slug}`);
  if (!response.ok) {
    throw new Error('Article not found');
  }
  return response.json();
};

const fetchRelatedArticles = async (slug) => {
  try {
    const response = await fetch(`/api/articles/${slug}/related`);
    if (!response.ok) {
      throw new Error('Failed to fetch related articles');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching related articles:', error);
    return [];
  }
};

// Функция для получения правильного пути к изображению
const getImagePath = (imagePath, size = 'preview') => {
  if (!imagePath) return '/api/articles/uploads/original/default-article.jpg';
  if (imagePath.startsWith('http')) return imagePath;
  return `/api/articles/uploads/${size}/${imagePath}`;
};

// Функция для форматирования даты с проверкой
const formatDate = (dateString) => {
  console.log('Formatting date:', { dateString, type: typeof dateString }); // Добавляем отладочный вывод
  
  if (!dateString) {
    console.warn('No date provided for formatting');
    return '';
  }
  
  try {
    const date = new Date(dateString);
    console.log('Parsed date:', { date, isValid: !isNaN(date.getTime()) }); // Добавляем отладочный вывод
    
    if (isNaN(date.getTime())) {
      console.warn('Invalid date:', dateString);
      return '';
    }
    
    const formatted = date.toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    
    console.log('Formatted date:', formatted); // Добавляем отладочный вывод
    return formatted;
  } catch (error) {
    console.error('Error formatting date:', { error, dateString });
    return '';
  }
};

const ArticleView = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadArticle = async () => {
      try {
        setLoading(true);
        const articleData = await fetchArticle(slug);
        console.log('Article data:', {
          title: articleData.title,
          createdAt: articleData.createdAt,
          updatedAt: articleData.updatedAt
        });
        setArticle(articleData);
        
        // Загружаем похожие статьи
        const related = await fetchRelatedArticles(slug);
        console.log('Related articles:', related.map(article => ({
          title: article.title,
          createdAt: article.createdAt,
          updatedAt: article.updatedAt
        })));
        setRelatedArticles(related);
        
        setError(null);
      } catch (err) {
        setError(err.message);
        console.error('Error loading article:', err);
      } finally {
        setLoading(false);
      }
    };

    loadArticle();
  }, [slug]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Skeleton variant="rectangular" height={400} sx={{ borderRadius: 2, mb: 4 }} />
        <Skeleton variant="text" height={60} sx={{ mb: 2 }} />
        <Skeleton variant="text" height={30} sx={{ mb: 4 }} width="60%" />
        <Skeleton variant="rectangular" height={200} sx={{ mb: 4 }} />
      </Container>
    );
  }

  if (error || !article) {
    navigate('/404');
    return null;
  }

  // Формируем структурированные данные для SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: article.title,
    description: article.description,
    image: article.image,
    datePublished: article.createdAt,
    dateModified: article.updatedAt,
    author: {
      "@type": "Person",
      name: article.author?.name || 'Admin'
    },
    publisher: {
      "@type": "Organization",
      name: "Your Site Name",
      logo: {
        "@type": "ImageObject",
        url: "https://your-domain.com/logo.png"
      }
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": window.location.href
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Helmet>
        <title>{article.metaTitle || article.title}</title>
        <meta name="description" content={article.metaDescription || article.description} />
        <meta name="keywords" content={article.keywords} />
        
        {/* Open Graph теги */}
        <meta property="og:title" content={article.metaTitle || article.title} />
        <meta property="og:description" content={article.metaDescription || article.description} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={getImagePath(article.image, 'preview')} />
        <meta property="og:site_name" content="Your Site Name" />
        <meta property="article:published_time" content={article.createdAt} />
        <meta property="article:modified_time" content={article.updatedAt} />
        <meta property="article:author" content={article.author?.name || 'Admin'} />
        
        {/* Twitter Card теги */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.metaTitle || article.title} />
        <meta name="twitter:description" content={article.metaDescription || article.description} />
        <meta name="twitter:image" content={getImagePath(article.image, 'preview')} />
        
        {/* Schema.org разметка */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 4, md: 6 } }}>
        {/* Изображение статьи */}
        <ArticleImage
          src={getImagePath(article.image)}
          alt={article.title}
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.4 }}
        />

        <ArticleHeader>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            <Typography 
              variant="h1" 
              sx={{ 
                fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' },
                lineHeight: { xs: 1.3, sm: 1.4 },
                mb: { xs: 2, sm: 3 },
                fontWeight: 'bold'
              }}
            >
              {article.title}
            </Typography>

            <AuthorCard>
              <Avatar 
                sx={{ 
                  width: { xs: 48, sm: 56 }, 
                  height: { xs: 48, sm: 56 },
                  mb: { xs: 1, sm: 0 },
                  mr: { xs: 0, sm: 2 }
                }}
              >
                {article.author?.name?.[0] || 'A'}
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6" sx={{ mb: 0.5 }}>
                  {article.author?.name || 'Admin'}
                </Typography>
                <ArticleMetadata>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AccessTimeIcon sx={{ fontSize: { xs: 16, sm: 20 } }} />
                    <Typography variant="body2">
                      {formatDate(article.createdAt)}
                    </Typography>
                  </Box>
                </ArticleMetadata>
              </Box>
            </AuthorCard>

            {article.tags && article.tags.length > 0 && (
              <Box sx={{ 
                mt: 2, 
                mb: 3,
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 1 
              }}>
                {article.tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    color="primary"
                    variant="outlined"
                    size="medium"
                    sx={{ 
                      borderRadius: '16px',
                      fontSize: '0.9rem',
                      '&:hover': {
                        backgroundColor: 'primary.main',
                        color: 'white'
                      }
                    }}
                  />
                ))}
              </Box>
            )}

            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.4, delay: 0.2 }}
            >
              <MarkdownContent elevation={1}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {article.content}
                </ReactMarkdown>
              </MarkdownContent>
            </motion.div>

            {article.categories && article.categories.length > 0 && (
              <CategoryContainer>
                {article.categories.map((category) => (
                  <Chip
                    key={category}
                    label={category}
                    variant="outlined"
                    sx={{ 
                      borderRadius: '16px',
                      '&:hover': {
                        backgroundColor: 'primary.main',
                        color: 'white'
                      }
                    }}
                  />
                ))}
              </CategoryContainer>
            )}

            {relatedArticles.length > 0 && (
              <RelatedArticlesSection>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    mb: { xs: 2, sm: 3 },
                    fontSize: { xs: '1.5rem', sm: '2rem' },
                    fontWeight: 'bold'
                  }}
                >
                  Похожие статьи
                </Typography>
                <Grid container spacing={{ xs: 2, sm: 3 }}>
                  {relatedArticles.map((relatedArticle, index) => (
                    <Grid item xs={12} sm={6} md={4} key={relatedArticle._id}>
                      <RelatedArticleCard
                        component={motion.div}
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.4, delay: 0.1 * index }}
                        onClick={() => navigate(`/blog/${relatedArticle.slug}`)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <CardMedia
                          component="img"
                          image={getImagePath(relatedArticle.image)}
                          alt={relatedArticle.title}
                          sx={{
                            height: { xs: '200px', sm: '200px' }
                          }}
                        />
                        <CardContent>
                          {relatedArticle.categories && relatedArticle.categories.length > 0 && (
                            <Box sx={{ mb: { xs: 0.5, sm: 1 } }}>
                              {relatedArticle.categories.slice(0, 2).map(category => (
                                <Chip
                                  key={category}
                                  label={category}
                                  size="small"
                                  sx={{ mr: 0.5, mb: 0.5 }}
                                />
                              ))}
                            </Box>
                          )}
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: { xs: '1rem', sm: '1.25rem' },
                              mb: { xs: 0.5, sm: 1 },
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical'
                            }}
                          >
                            {relatedArticle.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              display: { xs: 'none', sm: 'block' },
                              mb: 1,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical'
                            }}
                          >
                            {relatedArticle.description}
                          </Typography>
                          <Box sx={{ 
                            mt: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}>
                            <Typography variant="caption" color="text.secondary">
                              {formatDate(relatedArticle.createdAt)}
                            </Typography>
                          </Box>
                        </CardContent>
                      </RelatedArticleCard>
                    </Grid>
                  ))}
                </Grid>
              </RelatedArticlesSection>
            )}
          </motion.div>
        </ArticleHeader>
      </Container>
    </motion.div>
  );
};

export default ArticleView;
